import React from 'react';
import { useUser } from 'reactfire';
import { DeviceList } from '../components/DeviceList';
import { DeviceListPlaceholder } from '../components/DeviceListPlaceholder';
import { useDocumentTitle } from '../utils/useDocumentTitle';

export function Dashboard() {
    const { data: user, status } = useUser();
    useDocumentTitle('Dashboard');

    // check undefined as workaround for useUser bug
    // https://github.com/FirebaseExtended/reactfire/issues/495
    const isLoading = status === 'loading' || user === undefined;

    if (isLoading) {
        return (
            <DeviceListPlaceholder />
        );
    }

    if (!user) {
        return (
            <DeviceListPlaceholder />
        );
    }

    return (
        <DeviceList />
    );
}
