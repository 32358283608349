/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
    ref, push, child, remove,
} from 'firebase/database';
import React, {
    useState, useRef, ChangeEvent, useEffect, KeyboardEvent,
} from 'react';
import { useDatabase } from 'reactfire';
import {
    Input, Ref, Segment, Header, Icon, Confirm, Button, Table, Label, Popup,
} from 'semantic-ui-react';

import { SymbolInfo } from '../utils/types';
import { Device, PortfolioItem, PortfolioItemFields } from '../models/Device';
import { DeviceSettingsForm } from './DeviceSettingsForm';
import { InfoIcon } from './InfoIcon';
import { useStockData } from './StockDataProvider';

import styles from './DeviceCard.module.css';
import { useForm } from '../utils/useForm';
import { StockPicker, StockPickerRef } from './StockPicker';

import silhouette from '../images/rocket-silhouette-black.svg';

const defaultPortfolioItem: PortfolioItem = {
    originalPurchasePrice: '',
    shares: '',
    symbol: '',
};

export interface DeviceCardProps {
    device: Device;
    isNewlyAdded: boolean;
}

const maxSymbols = 15;

export function DeviceCard({ device, isNewlyAdded = false }: DeviceCardProps) {
    const deviceRef = ref(useDatabase(), `devices/${device.id}`);
    const { symbols } = useStockData();

    const onAddItem = async (data: Partial<PortfolioItem>) => {
        push(child(deviceRef, 'portfolio'), data); // intentionally not awaiting
    };

    const addSymbolRef = useRef<StockPickerRef>(null);
    const {
        errors,
        data: itemToAdd,
        update: onFormChange,
        clear: onFormClear,
        submit: onFormSubmit,
    } = useForm<PortfolioItem>({
        initialValue: defaultPortfolioItem,
        fields: PortfolioItemFields,
        onSave: onAddItem,
    });

    const onBlur = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if ((ev.target as HTMLInputElement)?.form === (ev.relatedTarget as HTMLInputElement)?.form) {
            return;
        }

        if (!itemToAdd.originalPurchasePrice && !itemToAdd.shares && !itemToAdd.symbol) {
            addSymbolRef.current?.clear();
            onFormClear();
        }
    };

    const onSearchSelected = (id: string | null) => {
        onFormChange({
            symbol: id || '',
        });
    };
    const onAddItemPress = async () => {
        let symbolToAdd = '';
        if (itemToAdd.symbol.trim().length > 0) {
            symbolToAdd = itemToAdd.symbol;
        }

        let shares = itemToAdd.shares.replace(/[^0-9.]/g, '');
        if (shares.startsWith('.')) {
            shares = `0${shares}`; // delete non-numeric characters
        }

        let originalPurchasePrice = itemToAdd.originalPurchasePrice.replace(/[^0-9.]/g, '');
        if (originalPurchasePrice.startsWith('.')) {
            originalPurchasePrice = `0${originalPurchasePrice}`; // delete non-numeric characters
        }

        const formattedItemToAdd: PortfolioItem = {
            symbol: symbolToAdd,
            shares,
            originalPurchasePrice,
        };
        await onFormChange(formattedItemToAdd);
        const submitErrors = await onFormSubmit(formattedItemToAdd);
        if (!submitErrors) {
            onFormClear();
            addSymbolRef.current?.clear();
            addSymbolRef.current?.focus();
        }
    };
    const onEditItemToAdd = (field: keyof PortfolioItem, ev: ChangeEvent<HTMLInputElement> | string) => {
        onFormChange({
            [field]: typeof ev === 'string' ? ev : ev.currentTarget.value,
        });
    };
    const onAddItemHotKeyPress = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            onAddItemPress();
        }
    };

    const onDeleteItem = async (id: string) => {
        await remove(child(deviceRef, `portfolio/${id}`));
    };
    const [activeRemoveConfirmModal, setActiveRemoveConfirmModal] = useState<string | null>(null);

    const [expandedRows, setExpandedRows] = useState<string[]>([]);
    const onToggleRowExpanded = (symbol: string) => {
        if (expandedRows.includes(symbol)) {
            setExpandedRows(expandedRows.filter(r => r !== symbol));
        }
        else {
            setExpandedRows([...expandedRows, symbol]);
        }
    };

    const [showSettings, setShowSettings] = useState(false);
    const toggleShowSettings = () => setShowSettings(!showSettings);

    const [outerSegmentRef, setOuterSegmentRef] = useState<HTMLElement | null>(null);
    useEffect(() => {
        if (isNewlyAdded) {
            outerSegmentRef?.scrollIntoView();
            setShowSettings(true);
        }
    }, [outerSegmentRef, isNewlyAdded]);

    type PortfolioAggregate = {
        [symbol: string]: {
            items: { [id: string]: PortfolioItem };
            totalShares: number;
            totalCost: number;
        };
    };
    const aggregated: PortfolioAggregate = {};
    Object.keys(device.portfolio || {}).forEach(portfolioItemId => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const portfolioItem = device.portfolio![portfolioItemId];
        if (!(portfolioItem.symbol in aggregated)) {
            aggregated[portfolioItem.symbol] = {
                items: {},
                totalShares: 0,
                totalCost: 0,
            };
        }
        aggregated[portfolioItem.symbol].items[portfolioItemId] = portfolioItem;
        aggregated[portfolioItem.symbol].totalShares += parseFloat(portfolioItem.shares);
        aggregated[portfolioItem.symbol].totalCost += parseFloat(portfolioItem.originalPurchasePrice) * parseFloat(portfolioItem.shares);
    });

    const numSymbols = Object.keys(aggregated).length;
    let symbolDisplayColor: string | undefined;
    if (numSymbols >= maxSymbols) {
        symbolDisplayColor = '#c51818';
    }

    const symbolsFilter = numSymbols === maxSymbols ? Object.keys(aggregated) : undefined;

    return (
        <Ref innerRef={setOuterSegmentRef}>
            <Segment className={styles.outerSegment}>
                <Header dividing>
                    <img src={silhouette} className={styles.deviceIcon} alt="the stock rocket" />
                    {device.displaySymbol}
                    <span className={styles.deviceProductName}>
                        Stock Rocket™
                    </span>
                </Header>
                <div className={styles.settingsContainer} onClick={toggleShowSettings}>
                    <span className={styles.settingsText}>Advanced Settings</span>
                    <Icon name="cog" size="large" />
                </div>
                {showSettings && (
                    <>
                        <DeviceSettingsForm device={device} onClose={() => setShowSettings(false)} />
                        <Header as="h4">Portfolio</Header>
                    </>
                )}
                <Table striped compact="very" selectable>
                    <Table.Header>
                        <Table.Row className={styles.tableHeadingRow}>
                            <Table.HeaderCell className={styles.infoIconTooltipHack}>
                                Symbol
                                {' '}
                                <span style={{ color: symbolDisplayColor }}>
                                    (
                                    {numSymbols}
                                    /
                                    {maxSymbols}
                                    )
                                </span>
                                {' '}
                                <InfoIcon tooltip={`The name of the stock or currency (limit to ${maxSymbols} unique symbols per device`} />
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.infoIconTooltipHack}>
                                Shares
                                {' '}
                                <InfoIcon tooltip="The number of shares owned" />
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.infoIconTooltipHack}>
                                Cost / Share
                                {' '}
                                <InfoIcon tooltip="The average cost of each share owned (when you purchased it)" />
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {Object.keys(aggregated).map(uniqId => {
                            const aggregate = aggregated[uniqId];
                            let symbol: SymbolInfo | null = null;
                            if (uniqId in symbols) {
                                symbol = symbols[uniqId];
                            }
                            const localStringOptions = {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            };
                            return (
                                <React.Fragment key={uniqId}>
                                    <Table.Row onClick={() => onToggleRowExpanded(uniqId)} className={styles.tableRow}>
                                        <Table.Cell>
                                            <Icon name={expandedRows.includes(uniqId) ? 'caret down' : 'caret right'} />
                                            <span className={styles.symbolName}>{symbol?.symbol ?? uniqId}</span>
                                            { symbol === null ? (
                                                <>
                                                    <Icon name="exclamation triangle" className={styles.symbolError} title="Unknown symbol" />
                                                    <span className={styles.symbolSubtext}>Unknown symbol</span>
                                                </>
                                            ) : (
                                                <span className={styles.symbolSubtext}>
                                                    {symbol.name ?? ''}
                                                </span>
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>{aggregate.totalShares}</Table.Cell>
                                        <Table.Cell>
                                            $
                                            {(aggregate.totalCost / aggregate.totalShares).toLocaleString(undefined, localStringOptions)}
                                        </Table.Cell>
                                        <Table.Cell />
                                    </Table.Row>
                                    {expandedRows.includes(uniqId) && (
                                        <>
                                            {Object.keys(aggregate.items).map((id, i) => {
                                                const item = aggregate.items[id];
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <Table.Row key={i}>
                                                        <Table.Cell className={styles.lotCell}>
                                                            Lot
                                                            {' '}
                                                            {i + 1}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {/* <Input type="text" placeholder='symbol' transparent value={item.shares} /> */}
                                                            {item.shares}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {/* $
                                                            <Input
                                                                type="text"
                                                                placeholder="symbol"
                                                                transparent
                                                                value={parseFloat(
                                                                    item.originalPurchasePrice,
                                                                ).toLocaleString(undefined, localStringOptions)}
                                                            /> */}

                                                            $
                                                            {parseFloat(item.originalPurchasePrice).toLocaleString(undefined, localStringOptions)}
                                                        </Table.Cell>
                                                        <Table.Cell className={styles.actionsCell} collapsing>
                                                            <Label as="a" color="red" size="tiny" onClick={() => setActiveRemoveConfirmModal(id)}>
                                                                <Icon name="trash" />
                                                                Remove
                                                            </Label>
                                                            <Confirm
                                                                open={activeRemoveConfirmModal === id}
                                                                header="Are you sure you want to remove this lot?"
                                                                content={
                                                                    `${symbol?.symbol ?? uniqId} Lot ${i + 1} `
                                                                    + '('
                                                                    + `${item.shares} share${parseFloat(item.shares) !== 1 ? 's' : ''}`
                                                                    + ' at '
                                                                    + `$${item.originalPurchasePrice}/share)`
                                                                }
                                                                confirmButton={(
                                                                    <Button primary={false} color="red">
                                                                        <Icon name="trash" />
                                                                        Remove
                                                                    </Button>
                                                                )}
                                                                cancelButton="Cancel"
                                                                onConfirm={() => onDeleteItem(id)}
                                                                onCancel={() => setActiveRemoveConfirmModal(null)}
                                                            />
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row className={styles.footerRow}>
                            <Table.HeaderCell>
                                <form id={`${device.id}-add-new`}>
                                    <StockPicker
                                        placeholder="symbol"
                                        className={styles.symbolInput}
                                        onSelect={onSearchSelected}
                                        onBlur={onBlur}
                                        onKeyPress={onAddItemHotKeyPress}
                                        error={!!errors.symbol}
                                        transparent
                                        ref={addSymbolRef}
                                        symbolFilter={symbolsFilter}
                                    />
                                    <Popup
                                        content={errors.symbol}
                                        open={!!errors.symbol}
                                        position="bottom left"
                                        style={{ zIndex: 999 }}
                                        trigger={(<div />)}
                                    />
                                </form>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Popup
                                    content={errors.shares}
                                    open={!!errors.shares}
                                    position="bottom left"
                                    style={{ zIndex: 999 }}
                                    trigger={(
                                        <Input
                                            type="text"
                                            placeholder="shares"
                                            transparent
                                            value={itemToAdd.shares}
                                            onChange={ev => onEditItemToAdd('shares', ev)}
                                            onBlur={onBlur}
                                            onKeyPress={onAddItemHotKeyPress}
                                            min={0}
                                            className={styles.numSharesInput}
                                            error={!!errors.shares}
                                            form={`${device.id}-add-new`}
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div className={styles.costPerShareInputWrapper}>
                                    <span>$</span>
                                    <Popup
                                        content={errors.originalPurchasePrice}
                                        open={!!errors.originalPurchasePrice}
                                        position="bottom left"
                                        style={{ zIndex: 999 }}
                                        trigger={(
                                            <Input
                                                type="text"
                                                placeholder="cost/share"
                                                transparent
                                                value={itemToAdd.originalPurchasePrice}
                                                onChange={ev => onEditItemToAdd('originalPurchasePrice', ev)}
                                                onBlur={onBlur}
                                                onKeyPress={onAddItemHotKeyPress}
                                                min={0}
                                                step={0.01}
                                                className={styles.costPerShareInput}
                                                error={!!errors.originalPurchasePrice}
                                                form={`${device.id}-add-new`}
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className={styles.addRowCell}>
                                <Label as="a" onClick={onAddItemPress}>
                                    <Icon name="add" />
                                    Add
                                </Label>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Segment>
        </Ref>
    );
}
