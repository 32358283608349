import React, { useEffect, useState } from 'react';
import { validCurrencies } from '../utils/constants';

import styles from './ScreenSimulation.module.css';

export interface DeviceSimOptions {
    displaySymbol?: string;
    currency?: 'dollar' | 'euro' | 'yen' | 'pound';
    formatting?: 'magnitude' | 'full';
    deltaMode?: 'lastClose' | 'originalPurchasePrice';
    enablePostMarket?: boolean;
    shouldDisplayPrices?: boolean;
    brightness?: number;
    contrast?: number;
    hardwareVersion: string;
}

export interface ScreenSimulationProps {
    device: DeviceSimOptions;

    totalValue?: number;
    change?: number;
    percentChange?: number;

    line1?: string;
    line2?: string;
}

const trillions = 1000 * 1000 * 1000 * 1000;
const billions = 1000 * 1000 * 1000;
const millions = 1000 * 1000;
const thousands = 1000;

// Sync with TickerWorker.FormatCurrency in c#
function formatCurrency(originalValue: number, plusMinus: boolean, device: DeviceSimOptions) {
    const currencyKey = device.currency ?? 'dollar';
    const currencyInfo = validCurrencies.find(c => c.key === currencyKey);
    const currencySymbol = currencyInfo?.text ?? '$';

    let plus = '';
    if (plusMinus && originalValue > 0) {
        plus = '+';
    }

    let value = originalValue;
    if (device.formatting === 'magnitude') {
        let suffix = '';
        let decimals = 2;

        if (Math.abs(value) >= trillions) {
            suffix = 'T';
            value /= trillions;
        }
        else if (Math.abs(value) >= billions) {
            suffix = 'B';
            value /= billions;
        }
        else if (Math.abs(value) >= millions) {
            suffix = 'M';
            value /= millions;
        }
        else if (Math.abs(value) >= thousands) {
            suffix = 'K';
            value /= thousands;
        }

        if (Math.abs(value) < 0.01) {
            value = 0;
        }

        if (suffix !== '') {
            if (Math.abs(value) > 100) {
                decimals = 0;
            }
            else if (Math.abs(value) > 10) {
                decimals = 1;
            }
            else {
                decimals = 2;
            }
        }

        const formatted = plus + currencySymbol + value.toFixed(decimals) + suffix;

        return formatted;
    }
    // else if (configuration.Formatting == FormattingType.Full)

    return plus + currencySymbol + (new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
    }).format(value));
}

function formatPercent(value: number) {
    let plus = '';
    if (value > 0) {
        plus = '+';
    }
    return `${plus + value.toFixed(2)}%`;
}

function clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
}

export function ScreenSimulation({
    device,
    line1: dl1 = '', line2: dl2 = '',
    totalValue = 12345.678, change = 123.45, percentChange = 12.34,
}: ScreenSimulationProps) {
    let line1 = dl1;
    let line2 = dl2;
    if (!line1 && !line2) {
        if (device.shouldDisplayPrices) {
            line1 = `${device.displaySymbol} ${formatCurrency(totalValue, false, device)}`;
            line2 = `${formatCurrency(change, true, device)} ${formatPercent(percentChange)}`;
        }
        else {
            line1 = device.displaySymbol ?? '';
            line2 = formatPercent(percentChange);
        }
    }

    const [lineTrunc1, setLineTrunc1] = useState(line1.substring(0, 17));
    const [lineTrunc2, setLineTrunc2] = useState(line2.substring(0, 17));

    useEffect(() => {
        let scrollIndex = 0;

        const tick = () => {
            const index = scrollIndex - 1;

            const line1max = line1.length - 16;
            const line2max = line2.length - 16;

            const i1 = clamp(index, 0, line1max);
            const i2 = clamp(index, 0, line2max);

            const trunc1 = line1.substring(i1, i1 + 16);
            const trunc2 = line2.substring(i2, i2 + 16);

            setLineTrunc1(trunc1);
            setLineTrunc2(trunc2);

            // Add 1 to each end to give an extra second to stop, plus 1 more to include the last character
            scrollIndex = (scrollIndex + 1) % (Math.max(line1.length, line2.length) - 13);
        };

        const interval = setInterval(() => {
            tick();
        }, 1000);
        tick();

        return () => {
            clearInterval(interval);
        };
    }, [line1, line2]);

    return (
        <div className={styles.container}>
            <pre className={styles.line}>{lineTrunc1}</pre>
            <pre className={styles.line}>{lineTrunc2}</pre>
        </div>
    );
}
