import React from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import { useDocumentTitle } from '../utils/useDocumentTitle';

export function NotFound() {
    useDocumentTitle('Not Found');
    return (
        <LoginContainer textAlign="left">
            <Header as="h1">Not Found</Header>
            <p>
                The page you are looking for does not exist.
            </p>
            <p>
                <Link to="/">Return to home page.</Link>
            </p>
        </LoginContainer>
    );
}
