import { sendEmailVerification } from 'firebase/auth';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUser } from 'reactfire';
import { Button, Header, List } from 'semantic-ui-react';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import { useTimedValue } from '../utils/useTimedValue';

export function EmailVerification() {
    const [loading, setLoading] = useState(false);
    const {
        value: submitButtonValue,
        isRunning: submitButtonValueAlt,
        setValue: setSubmitButtonValue,
    } = useTimedValue('Send verification email', 5000);
    const { data: user, status } = useUser();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect');

    const onClick = async () => {
        if (status !== 'success' || !user) {
            return;
        }

        setLoading(true);
        try {
            const options = redirectUrl ? { url: redirectUrl } : null;
            await sendEmailVerification(user, options);
            setSubmitButtonValue('Sent');
        }
        catch {
            setSubmitButtonValue('Error sending email verification');
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <LoginContainer textAlign="left">
            <Header as="h1" textAlign="center">
                Verify your email
            </Header>
            <p style={{ textAlign: 'left' }}>
                We sent you an email to verify your email address.
                Please click the link in the email to complete the verification process.
            </p>
            <List bulleted>
                <List.Item>
                    If you didn&apos;t get an email, check your spam folder.
                </List.Item>
                <List.Item>
                    If you still don&apos;t see it, you can try clicking the button below to send it again.
                </List.Item>
            </List>
            <Button
                primary
                fluid
                loading={loading}
                onClick={onClick}
                disabled={submitButtonValueAlt || loading}
            >
                {submitButtonValue}
            </Button>
        </LoginContainer>
    );
}
