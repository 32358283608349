import axios from 'axios';
import React, { useState } from 'react';
import { useAuth } from 'reactfire';
import {
    Button, Confirm, Form, Icon,
} from 'semantic-ui-react';

export interface LinkDeviceButtonProps {
    deviceId: string;
}

export function UnlinkDeviceButton({ deviceId }: LinkDeviceButtonProps) {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [unlinkConfirmModal, setUnlinkConfirmModal] = useState<boolean>(false);

    const onUnlinkDevice = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        const token = await currentUser?.getIdToken() ?? '';
        try {
            await axios({
                method: 'POST',
                url: `/unlinkDevice/?id=${encodeURIComponent(deviceId)}`,
                headers: {
                    Authorization: `Bearer ${encodeURIComponent(token)}`,
                },
            });
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setLoading(false);
            setUnlinkConfirmModal(false);
        }
    };

    const confirmButton = loading ? (
        <Button primary={false} color="red" disabled>
            <Icon loading name="spinner" />
            Unlinking...
        </Button>
    ) : (
        <Button primary={false} color="red">
            <Icon name="broken chain" />
            Unlink
        </Button>
    );

    return (
        <>
            {loading ? (
                <Form.Button color="red" disabled>
                    <Icon loading name="spinner" />
                    Unlinking...
                </Form.Button>
            ) : (
                <Form.Button color="red" onClick={() => setUnlinkConfirmModal(true)}>
                    <Icon name="broken chain" />
                    Unlink Device
                </Form.Button>
            )}
            <Confirm
                open={unlinkConfirmModal}
                // eslint-disable-next-line max-len
                content="Are you sure you want to unlink this device? Any settings you configured for this device will be lost. This action cannot be undone."
                confirmButton={confirmButton}
                cancelButton="Cancel"
                onConfirm={() => onUnlinkDevice()}
                onCancel={() => setUnlinkConfirmModal(false)}
            />
        </>
    );
}
