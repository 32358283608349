import axios from 'axios';
import { User } from 'firebase/auth';
import { ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAuth, useDatabase, useDatabaseObjectData } from 'reactfire';
import {
    Button, CheckboxProps, Dimmer, Divider, Form, Header, Icon, Loader, Modal, Placeholder, Popup, Table,
} from 'semantic-ui-react';
import type { Components, HeadingProps } from 'react-markdown/lib/ast-to-react';
import { PluggableList } from 'react-markdown/lib/react-markdown';
import type { UserDataModel } from '../models/UserDataModel';

import styles from './TermsOfService.module.css';
import tosUri from '../tos/terms.md';
import privacyUri from '../tos/privacy.md';

const latestTosDate = new Date('2022-09-01');

interface HeadingRendererProps {
    children: React.ReactNode;
    level: number;
}
const flatten = (text: string, child: string | React.ReactNode): string => {
    if (typeof child === 'string') {
        return text + child;
    }
    if (child && typeof child === 'object' && 'props' in child) {
        return React.Children.toArray(child.props.children).reduce(flatten, text);
    }
    return text;
};
function HeadingRenderer({ children: c, level }: HeadingRendererProps) {
    const children = React.Children.toArray(c);
    const text = children.reduce(flatten, '');
    const slug = text.toLowerCase().replace(/^\d+\.[^\w]+/, '').replace(/\W/g, '-');
    return React.createElement(`h${level}`, { id: slug }, c);
}
function HeadingRendererFn({ node, ...props }: HeadingProps) {
    return <HeadingRenderer {...props} />;
}

const markdownComponentOptions: Components = {
    a: ({ href, node, ...props }) => {
        if (href?.startsWith('#')) {
            return <a href={href} {...props}>{props.children}</a>;
        }
        return <a href={href} {...props} target="_blank" rel="noreferrer">{props.children}</a>;
    },
    h2: HeadingRendererFn,
    h3: HeadingRendererFn,
    table: ({ children }) => <Table striped>{children}</Table>,
    thead: ({ children }) => <Table.Header>{children}</Table.Header>,
    tr: ({ children }) => <Table.Row>{children}</Table.Row>,
    th: ({ children }) => <Table.HeaderCell verticalAlign="top">{children}</Table.HeaderCell>,
    tbody: ({ children }) => <Table.Body>{children}</Table.Body>,
    td: ({ children }) => <Table.Cell verticalAlign="top">{children}</Table.Cell>,
};
const plugins: PluggableList = [remarkGfm];

interface TosBoxProps {
    markdown: string | null;
    maxHeight?: string | null;
    className?: string | null;
}
export const TosBox = React.memo(({ markdown, maxHeight = '400px', className = styles.tosBox }: TosBoxProps) => {
    const isLoading = markdown === null;
    return (
        <div style={{ maxHeight: maxHeight || undefined }} className={className || undefined}>
            {isLoading && (
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line />
                    </Placeholder.Header>
                    {Array.from(new Array(4)).map((_, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Placeholder.Paragraph key={i}>
                            {Array.from(new Array(5)).map((__, j) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Placeholder.Line key={j} />
                            ))}
                        </Placeholder.Paragraph>
                    ))}

                </Placeholder>
            )}
            {!isLoading && (
                <ReactMarkdown components={markdownComponentOptions} remarkPlugins={plugins}>{markdown || ''}</ReactMarkdown>
            )}
        </div>
    );
});

export interface TermsOfServiceModalProps {
    user: User,
}
export function TermsOfServiceModal({ user }: TermsOfServiceModalProps) {
    const auth = useAuth();
    const userDataRef = ref(useDatabase(), `users/${user.uid}`);
    const { data: userData, status } = useDatabaseObjectData<UserDataModel>(userDataRef, { idField: 'id' });

    const [tosMarkdown, setTosMarkdown] = useState<string | null>(null);
    const [privacyMarkdown, setPrivacyMarkdown] = useState<string | null>(null);
    useEffect(() => {
        axios.get(tosUri).then(response => {
            setTosMarkdown(response.data);
        });
    }, []);
    useEffect(() => {
        axios.get(privacyUri).then(response => {
            setPrivacyMarkdown(response.data);
        });
    }, []);
    const isMarkdownLoading = tosMarkdown === null;

    const [accepted, setAccepted] = useState(false);
    const [accepted2, setAccepted2] = useState(false);
    const [loading, setLoading] = useState(false);

    const onAcceptedCheckboxClick = async (ev: unknown, { checked }: CheckboxProps) => {
        setAccepted(checked || false);
    };
    const onAccepted2CheckboxClick = async (ev: unknown, { checked }: CheckboxProps) => {
        setAccepted2(checked || false);
    };

    if (status !== 'success') {
        return null;
    }

    const userTosDate = userData?.dateAcceptedTos ? new Date(userData.dateAcceptedTos) : null;
    const open = !userTosDate || userTosDate < latestTosDate;

    const onAccept = async () => {
        if (!open || loading || !accepted) {
            return;
        }

        setLoading(true);

        const token = await user.getIdToken() ?? '';
        try {
            // const response =
            await axios({
                method: 'POST',
                url: '/acceptTos',
                headers: {
                    Authorization: `Bearer ${encodeURIComponent(token)}`,
                },
            });
            window.location.reload();
        }
        catch (e) {
            // TODO: display error box?
            // alert('An unknown error occurred.');
            setLoading(false);
        }
    };

    const onLogout = () => {
        auth.signOut();
    };

    return (
        <Modal open={open || loading}>
            <Modal.Header>Terms and Conditions and Privacy Policy</Modal.Header>
            <Modal.Content>
                <Header as="h4">Please review our terms of service and privacy policy.</Header>
                <Divider />
                <TosBox markdown={tosMarkdown} />
                <Divider />
                <TosBox markdown={privacyMarkdown} />
                <Divider />
                <div>
                    <p>You must accept these terms to continue using this application.</p>
                    <p>
                        <Form.Checkbox
                            label="I accept the Terms of Service and Privacy Policy"
                            name="accept"
                            autoComplete="off"
                            onChange={onAcceptedCheckboxClick}
                            checked={accepted}
                        />
                    </p>
                    <p>
                        <Form.Checkbox
                            label="I acknowledge that all data and information displayed on the StockRocket or on
                                the website is provided “as is” for informational purposes only, and is not intended
                                for trading purposes or financial, investment, tax, legal, accounting or other advice.
                                I acknowledge that any data and information displayed is not guaranteed to be realtime
                                or accurate."
                            name="accept2"
                            autoComplete="off"
                            onChange={onAccepted2CheckboxClick}
                            checked={accepted2}
                        />
                    </p>
                </div>
                <Dimmer active={isMarkdownLoading} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            </Modal.Content>
            <Modal.Actions>
                {loading ? (
                    <Button disabled primary>
                        <Icon loading name="spinner" />
                        Accepting Terms...
                    </Button>
                ) : (
                    <>
                        <Button onClick={onLogout}>
                            Decline
                        </Button>
                        <Popup
                            content="You must accept the terms before proceeding"
                            disabled={accepted && accepted2}
                            trigger={(
                                <span>
                                    <Button primary disabled={!accepted || !accepted2} onClick={onAccept}>
                                        Proceed
                                    </Button>
                                </span>
                            )}
                        />
                    </>
                )}
            </Modal.Actions>
        </Modal>
    );
}
