import {
    endBefore, orderByChild, orderByKey, query, ref, startAt,
} from 'firebase/database';
import React, { useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { SuspenseWithPerf, useDatabase } from 'reactfire';
import {
    Container, Input, Table, Pagination,
} from 'semantic-ui-react';
import { ErrorScreen } from '../../components/ErrorScreen';
import { RequireRole } from '../../components/RequireRole';
import { UserDisplay } from '../../components/UserDisplay';
import { FeedbackModel } from '../../models/FeedbackModel';
import { useDocumentTitle } from '../../utils/useDocumentTitle';
import { usePaginated, UsePaginatedOptions } from '../../utils/usePaginated';

interface Sort {
    field: keyof FeedbackModel;
    ascending: boolean;
}

function FeedbackHelper() {
    useDocumentTitle('Admin - Feedback');

    const databaseRef = useDatabase();

    const [sort, setSort] = useState<Sort>({ field: 'timestamp', ascending: false });
    const [filter, setFilter] = useState<string>('');
    const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);

    const queryBuilder: UsePaginatedOptions<FeedbackModel>['queryBuilder'] = useCallback(
        constraints => {
            const cons = [...constraints];
            if (filter) {
                if (!cons.some(c => c.constructor.name.toLowerCase().includes('startat')
                    || c.constructor.name.toLowerCase().includes('startafter'))) {
                    cons.push(startAt(filter));
                }
                if (!cons.some(c => c.constructor.name.toLowerCase().includes('endat')
                    || c.constructor.name.toLowerCase().includes('endbefore'))) {
                    cons.push(endBefore(`${filter}\uf8ff`));
                }
            }
            return query(
                ref(databaseRef, 'feedback'),
                sort.field === 'id' ? orderByKey() : orderByChild(sort.field),
                ...cons,
            );
        },
        [databaseRef, sort, filter],
    );

    const onSort = (sortBy: keyof FeedbackModel) => {
        const newSort: Sort = {
            field: sortBy,
            ascending: sort.field === sortBy ? !sort.ascending : true,
        };
        setSort(newSort);
    };

    const {
        data: feedbacks, status, setPage, currentPage, knownTotalPages,
    } = usePaginated<FeedbackModel>({
        pageSize: 20,
        sortKey: sort.field,
        reverse: !sort.ascending,
        queryBuilder,
    });

    if (status === 'loading') {
        return null;
    }

    const sortDirection = sort.ascending ? 'ascending' : 'descending';
    const calcSortable = (field: keyof FeedbackModel) => (sort.field === field ? sortDirection : undefined);

    return (
        <Container>
            <Input
                type="text"
                placeholder="search currently sorted column"
                fluid
                icon="search"
                iconPosition="left"
                value={filter}
                onChange={(e, { value }) => setFilter(value)}
            />
            <Table sortable compact selectable>
                <Table.Header>
                    <Table.Row>
                        {/* <Table.HeaderCell sorted={calcSortable('id')} onClick={() => onSort('id')}>
                            Id
                        </Table.HeaderCell> */}
                        <Table.HeaderCell sorted={calcSortable('timestamp')} onClick={() => onSort('timestamp')}>
                            Timestamp
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={calcSortable('uid')} onClick={() => onSort('uid')}>
                            User Id
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Description
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {feedbacks?.map(feedback => (
                        <Table.Row
                            key={feedback.id}
                            active={selectedFeedback === feedback.id}
                            onClick={() => setSelectedFeedback(feedback.id ?? null)}
                        >
                            {/* <Table.Cell>
                                {feedback.id}
                            </Table.Cell> */}
                            <Table.Cell>
                                {new Date(feedback.timestamp).toLocaleString()}
                            </Table.Cell>
                            <Table.Cell>
                                <UserDisplay userId={feedback.uid} showId />
                            </Table.Cell>
                            <Table.Cell>
                                <pre>
                                    {feedback.description}
                                </pre>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination activePage={currentPage} totalPages={knownTotalPages + 1} onPageChange={(_, data) => setPage(data.activePage as number)} />
            {/* {selectedFeedback && (
                <div key={selectedFeedback} className={styles.userView}>
                    <DeviceList userId={selectedFeedback} />
                </div>
            )} */}
        </Container>
    );
}

export function Feedback() {
    return (
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <RequireRole requiredRole="admin">
                <SuspenseWithPerf fallback="loading..." traceId="admin-panel">
                    <FeedbackHelper />
                </SuspenseWithPerf>
            </RequireRole>
        </ErrorBoundary>
    );
}
