import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { SetupGuide } from '../components/SetupGuide/SetupGuide';
import { useDocumentTitle } from '../utils/useDocumentTitle';

export function WelcomePage() {
    useDocumentTitle('New User Setup');
    return (
        <Grid>
            <Grid.Column>
                <Container>
                    <SetupGuide />
                </Container>
            </Grid.Column>
        </Grid>
    );
}
