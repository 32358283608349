import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import { TosBox } from '../components/TermsOfService';
import { useDocumentTitle } from '../utils/useDocumentTitle';

import privacyUri from '../tos/privacy.md';

export function PrivacyPage() {
    useDocumentTitle('Terms of Service');

    const [privacyMarkdown, setTosMarkdown] = useState<string | null>(null);
    useEffect(() => {
        axios.get(privacyUri).then(response => {
            setTosMarkdown(response.data);
        });
    }, []);

    return (
        <LoginContainer textAlign="left">
            <TosBox markdown={privacyMarkdown} maxHeight={null} className={null} />
        </LoginContainer>
    );
}
