import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Segment } from 'semantic-ui-react';

import styles from './Footer.module.css';

export function Footer() {
    return (
        <Container className={styles.footer}>
            <Grid>
                <Grid.Row centered>
                    <Grid.Column width={16}>
                        <Segment className={styles.footerSegment}>
                            ©&nbsp;2023 Investment Novelty Products LLC. All Rights Reserved
                            {' |'}
                            &nbsp;
                            <Link to="/privacy">Privacy&nbsp;Policy</Link>
                            {' |'}
                            &nbsp;
                            <Link to="/terms">Terms&nbsp;of&nbsp;Service</Link>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}
