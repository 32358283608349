import {
    child, push, ref, update,
} from 'firebase/database';
import React, { useState } from 'react';
import { useDatabase } from 'reactfire';
import {
    Grid, Container, Header, Form, DropdownProps, Button, Input, Label,
} from 'semantic-ui-react';
import { validateDisplaySymbol } from '../../models/Device';
import { StockPicker } from '../StockPicker';

import styles from './SetupGuide.module.css';

const goals = [
    { key: 'single', text: 'Track a single stock or cryptocurrency', value: 'single' },
    { key: 'multi', text: 'Track an entire portfolio', value: 'multi' },
];

export interface PortfolioStepProps {
    deviceId: string;
}

export function PortfolioStep({ deviceId }: PortfolioStepProps) {
    const database = useDatabase();
    const deviceRef = ref(database, `devices/${deviceId}`);

    const [nickname, setNickname] = useState('');
    const [canShowNicknameValidationErrors, setCanShowNicknameValidationErrors] = useState(false);
    const onNicknameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setNickname(ev.target.value);
        setCanShowNicknameValidationErrors(true);
    };
    const nicknameErrors = validateDisplaySymbol(nickname);

    const [goal, setGoal] = useState<'single' | 'multi' | ''>('');
    const onGoalChange = (ev: unknown, { value }: DropdownProps) => {
        if (value === 'single' || value === 'multi' || value === '') {
            setGoal(value);
        }
    };

    const [uniqId, setUniqId] = useState<string | null>(null);
    const onStockSelected = (id: string | null) => {
        setUniqId(id);
    };

    const [saving, setSaving] = useState(false);

    const onSubmit = async () => {
        if (!uniqId) {
            return;
        }

        if (goal === 'single') {
            setSaving(true);
            await update(deviceRef, {
                nickname,
                displaySymbol: nickname,
            });
            await push(child(deviceRef, 'portfolio'), {
                symbol: uniqId,
                originalPurchasePrice: '1',
                shares: '1',
            });
        }
        else if (goal === 'multi') {
            setSaving(true);
            await update(deviceRef, {
                nickname,
                displaySymbol: nickname,
            });
        }
        else {
            return;
        }

        window.location.href = '/'; // Hard refresh required to get the data to load correctly sometimes
    };

    let canSubmit = !!goal && !!nickname;
    if (goal === 'single') {
        canSubmit = canSubmit && !!uniqId;
    }
    // else if (goal === 'multi') {
    //     canSubmit = canSubmit && !!nickname;
    // }

    return (
        <Grid>
            <Grid.Column>
                <Container>
                    <Form>
                        <p>All of the following settings can be changed later.</p>
                        <Header as="h3">Give your device a name</Header>
                        <p>It&apos;ll be shown on the device, so keep it short!</p>
                        <Form.Field error={canShowNicknameValidationErrors && !!nicknameErrors}>
                            <Input
                                placeholder="Enter a nickname"
                                value={nickname}
                                name="nickname"
                                id="nickname"
                                onChange={onNicknameChange}
                                maxLength={20}
                                autoFocus
                                autoComplete="off"
                            />
                            {canShowNicknameValidationErrors && nicknameErrors && (
                                <Label pointing prompt>
                                    {nicknameErrors}
                                </Label>
                            )}
                        </Form.Field>

                        <Header as="h3">How do you want to use your device?</Header>
                        <Form.Select
                            name="goal"
                            options={goals}
                            value={goal}
                            placeholder="Select one"
                            onChange={onGoalChange}
                            autoComplete="off"
                        />

                        {goal === 'single' && (
                            <>
                                <Header as="h3">Which one?</Header>
                                <p>We&apos;ll add it to your portfolio for you.</p>
                                <StockPicker onSelect={onStockSelected} />
                            </>
                        )}

                        {goal === 'multi' && (
                            <>
                                <Header as="h3">That&apos;s it for now</Header>
                                <p>You&apos;ll be able to configure your portfolio on the dashboard.</p>
                            </>
                        )}

                        <div style={{ marginTop: '1em' }} />

                        <Button.Group floated="right" size="large">
                            {saving ? (
                                <Button primary loading>Saving</Button>
                            ) : (
                                <Button primary onClick={onSubmit} disabled={!canSubmit}>Save &amp; go to my dashboard</Button>
                            )}
                        </Button.Group>
                        <div className={styles.clearfix} />
                    </Form>
                </Container>
            </Grid.Column>
        </Grid>
    );
}
