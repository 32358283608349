import {
    useCallback, useEffect, useRef, useState,
} from 'react';

type OnUpdateCallback<T> = (s: T) => void;
type SetStateUpdaterCallback<T> = (s: T) => T;
type SetStateAction<T> = (newState: T | SetStateUpdaterCallback<T>) => Promise<T>;

export function useStateAsync<T>(init: T): [T, SetStateAction<T>];
export function useStateAsync<T = undefined>(init?: T): [T | undefined, SetStateAction<T | undefined>];
export function useStateAsync<T>(init: T): [T, SetStateAction<T>] {
    const [state, setState] = useState<T>(init);
    const cbRef = useRef<OnUpdateCallback<T>[]>([]);

    const asyncSetState: SetStateAction<T> = useCallback<SetStateAction<T>>(newState => new Promise(resolve => {
        cbRef.current.push(resolve);
        setState(newState);
    }), [setState]);

    useEffect(() => {
        for (let i = 0; i < cbRef.current.length; i += 1) {
            cbRef.current[i](state);
        }
        cbRef.current = [];
    }, [state]);

    return [state, asyncSetState];
}
