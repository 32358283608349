import React, { useCallback, useMemo, useState } from 'react';
import { useDatabase, useUser } from 'reactfire';
import {
    Button, Container, Form, Icon, Label, Modal,
} from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {
    push, ref, serverTimestamp,
} from 'firebase/database';
import { useForm, UseFormFields } from '../../utils/useForm';

import styles from './FeedbackButton.module.css';
import { delayAsync } from '../../utils/delayAsync';
import { FeedbackSubmitModel } from '../../models/FeedbackModel';

const categories = [
    { key: 'problem', text: 'Report a Problem', value: 'problem' },
    { key: 'feature', text: 'Feature Request', value: 'feature' },
    { key: 'other', text: 'Other', value: 'other' },
];

const tags = [
    { key: 'device', text: 'Physical Device / Screen', value: 'device' },
    { key: 'dashboard', text: 'StockRocket Website', value: 'dashboard' },
    { key: 'data', text: 'Stock/Crypto Data', value: 'data' },
    { key: 'other', text: 'Other', value: 'other' },
];

const initialValue: FeedbackSubmitModel = {
    category: null,
    tags: [],
    description: '',
    browserInfo: {
        userAgent: window.navigator.userAgent,
        language: window.navigator.language,
        // cookieEnabled: window.navigator.cookieEnabled,
    },
};

const fields: UseFormFields<FeedbackSubmitModel> = {
    category: value => {
        if (!value || !['problem', 'feature', 'other'].includes(value)) {
            return 'You must specify a category';
        }

        return null;
    },
    tags: values => {
        let hasInvalidTag = false;
        values.forEach(value => {
            if (!['device', 'dashboard', 'data', 'other'].includes(value)) {
                hasInvalidTag = true;
            }
        });
        if (hasInvalidTag) {
            return 'One or more tags is invalid';
        }

        return null;
    },
    description: value => {
        if (!value) {
            return 'You must specify a description';
        }

        if (value.length > 1000) {
            return 'Description cannot be longer than 1000 characters';
        }

        return null;
    },
};

type ShowFeedbackButtonContextType = {
    hidden: boolean;
    show: () => void;
};
export const ShowFeedbackButtonContext = React.createContext({
    hidden: true,
    show: () => {},
} as ShowFeedbackButtonContextType);

export type FeedbackButtonProviderProps = {
    children?: React.ReactNode;
};
export function FeedbackButtonProvider({ children }: FeedbackButtonProviderProps) {
    const { data: user } = useUser();
    const database = useDatabase();
    const feedbackRef = ref(database, 'feedback');
    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackCompleted, setFeedbackCompleted] = useState(false);

    const onSave = async (data: Partial<FeedbackSubmitModel>) => {
        const d = {
            ...initialValue,
            ...data,
            uid: user?.uid,
            tags: (data.tags || []).join(','),
            timestamp: serverTimestamp(),
            browserInfo: {
                ...initialValue.browserInfo,
                url: window.location.href,
            },
        };
        console.log(d);
        await push(feedbackRef, d);
        await delayAsync(2000);
        setFeedbackCompleted(true);
    };

    const {
        data,
        isSaving,
        getSelectProps,
        getTextareaProps,
        submit,
        clearValidation,
    } = useForm({
        fields,
        initialValue,
        enableAllValidationOnSave: true,
        clearOnSuccess: true,
        onSave,
    });

    const onClose = () => {
        clearValidation();
        setModalOpen(false);
        setFeedbackCompleted(false);
    };

    const showFn = useCallback(() => {
        setModalOpen(true);
    }, [setModalOpen]);
    const value: ShowFeedbackButtonContextType = useMemo(() => ({
        hidden: !modalOpen,
        show: showFn,
    }), [modalOpen, showFn]);

    return (
        <ShowFeedbackButtonContext.Provider value={value}>
            {children}
            {user && (
                <Modal open={modalOpen} size="small" onClose={onClose}>
                    <Modal.Header>Send us your feedback!</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {
                                feedbackCompleted && (
                                    <p>
                                        Thank you for submitting your feedback!
                                        We take your feedback seriously and will review it as soon as possible.
                                    </p>
                                )
                            }
                            { !feedbackCompleted && (
                                <Form>
                                    <Form.Select
                                        label="Category"
                                        placeholder="Select a category"
                                        options={categories}
                                        required
                                        {...getSelectProps('category')}
                                    />
                                    <Form.Dropdown
                                        label="Tags"
                                        placeholder="What is this about?"
                                        multiple
                                        selection
                                        options={tags}
                                        {...getSelectProps('tags')}
                                    />
                                    <Form.Field
                                        control={TextareaAutosize}
                                        label="Your feedback"
                                        placeholder="Tell us more..."
                                        required
                                        maxRows={30}
                                        className={styles.textarea}
                                        {...getTextareaProps('description')}
                                    />
                                    <Label
                                        color={data.description.length > 1000 ? 'red' : undefined}
                                        basic
                                        className={styles.maxLengthLabel}
                                    >
                                        {data.description.length}
                                        /1000
                                    </Label>
                                    <div className={styles.clearfix} />
                                </Form>
                            )}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        {
                            feedbackCompleted && (
                                <Button onClick={onClose}>Close</Button>
                            )
                        }
                        {
                            !feedbackCompleted && (
                                <>
                                    <Button onClick={onClose}>Cancel</Button>
                                    <Button
                                        onClick={() => submit()}
                                        primary
                                        content={feedbackCompleted ? 'Thanks!' : 'Submit'}
                                        disabled={isSaving || feedbackCompleted}
                                        loading={isSaving}
                                    />
                                </>
                            )
                        }
                    </Modal.Actions>
                </Modal>
            )}
        </ShowFeedbackButtonContext.Provider>
    );
}

export type FeedbackButtonProps = {
    children: (context: ShowFeedbackButtonContextType) => React.ReactNode;
};
export function FeedbackButton({ children }: FeedbackButtonProps) {
    const { data: user } = useUser();
    if (!user) {
        return null;
    }

    return (
        <ShowFeedbackButtonContext.Consumer>
            {context => children(context)}
        </ShowFeedbackButtonContext.Consumer>
    );
}

export function FloatingFeedbackButton() {
    return (
        <FeedbackButton>
            {({ show }) => (
                <Container className={styles.container} textAlign="right">
                    <Button className={styles.button} circular primary size="huge" onClick={show}>
                        <Icon name="bullhorn" />
                        Feedback
                    </Button>
                </Container>
            )}
        </FeedbackButton>
    );
}
