import React, { useEffect, useState } from 'react';
import {
    SuspenseWithPerf, useDatabase, useDatabaseListData, useUser,
} from 'reactfire';
import {
    Container, Grid,
} from 'semantic-ui-react';
import {
    ref, orderByChild, equalTo, query,
} from 'firebase/database';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { Device } from '../models/Device';
import { DeviceCard } from './DeviceCard';
import { useStockData } from './StockDataProvider';
import { LinkDeviceButton } from './LinkDeviceButton';
import { delayAsync } from '../utils/delayAsync';
import { DeviceListPlaceholder } from './DeviceListPlaceholder';
import { SetupGuide } from './SetupGuide/SetupGuide';
import { ErrorScreen } from './ErrorScreen';

function DeviceListHelper({ userId }: DeviceListProps) {
    const { data: user } = useUser();
    const navigate = useNavigate();

    if (!user) {
        throw new Error('User not found');
    }

    const [rerenderCounter, forceRerender] = useState(0);

    const devicesRef = ref(useDatabase(), 'devices');
    const devicesQuery = query(devicesRef, orderByChild('ownerUid'), equalTo(userId ?? user.uid));
    let { data: devices, status } = useDatabaseListData<Device>(devicesQuery, { idField: 'id' });
    if (devices == null) {
        devices = [];
    }
    devices.sort((a, b) => (a.dateLinked || '').localeCompare(b.dateLinked || ''));

    const [latestAddedDeviceId, setLatestAddedDeviceId] = useState<string | null>(null);

    const onDeviceLinked = (deviceId: string) => {
        setLatestAddedDeviceId(deviceId);
        // race condition???
        delayAsync(100).then(() => {
            forceRerender(rerenderCounter + 1);
        });
    };

    const { isLoading: symbolsLoading } = useStockData();

    useEffect(() => {
        if (status === 'success' && (!devices || devices.length === 0) && !userId) {
            navigate('/welcome');
        }
    }, [devices, status, userId, navigate]);

    if (status === 'loading' || symbolsLoading) {
        return (<DeviceListPlaceholder />);
    }

    return (
        <Grid style={{ marginBottom: '1em' }}>
            <Grid.Column>
                <Container>
                    {devices.length > 0 || userId ? (
                        <>
                            {devices.map(device => (
                                <DeviceCard device={device} key={device.id} isNewlyAdded={latestAddedDeviceId === device.id} />
                            ))}
                            <LinkDeviceButton errorPosition="top" onSuccess={onDeviceLinked} />
                        </>
                    ) : (
                        <SetupGuide /* onSuccess={onDeviceLinked} */ />
                    )}
                </Container>
            </Grid.Column>
        </Grid>
    );
}

export interface DeviceListProps {
    userId?: string;
}

export function DeviceList({ userId }: DeviceListProps) {
    return (
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <SuspenseWithPerf fallback="loading..." traceId="load-device-list">
                <DeviceListHelper userId={userId} />
            </SuspenseWithPerf>
        </ErrorBoundary>
    );
}
