export const firebaseErrorMappings = {
    'auth/invalid-email': 'The provided email address was not valid.',
    'auth/user-disabled': 'The account associated with this email address has been disabled.',
    'auth/user-not-found': 'There is no account associated with this email address',
    'auth/wrong-password': 'The password is incorrect or the account does not have a password.',

    'auth/email-already-in-use': 'The email address is already in use by another account.',
    'auth/weak-password': 'The password was not strong enough.',

    // 'auth/account-exists-with-different-credential': 'An account already exists with the same email address',
    // 'auth/auth-domain-config-required': 'This operation requires a successful fetching of configuration.',
    'auth/cancelled-popup-request': 'The sign-in popup was cancelled.',
    // 'auth/operation-not-allowed': 'This operation is not allowed.',
    // 'auth/operation-not-supported-in-this-environment': 'This operation is not supported in this environment.',
    'auth/popup-blocked': 'The popup was blocked by your browser. Make sure to allow popups from this website.',
    'auth/popup-closed-by-user': 'The popup was closed before signing in.',
    // 'auth/unauthorized-domain': 'This domain is not authorized for OAuth operations.',

    'auth/too-many-requests': 'Too many unsuccessful login attempts. Please try again later.',
    'auth/network-request-failed': 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.',

    unknown: 'An unknown error has occurred.',
};

export function getFirebaseErrorMessage(code: string): string {
    return code in firebaseErrorMappings
        ? firebaseErrorMappings[code as keyof typeof firebaseErrorMappings]
        : firebaseErrorMappings.unknown;
}
