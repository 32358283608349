import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useAuth } from 'reactfire';
import {
    Button, Form, Icon, Input, List, ListItem, Message,
} from 'semantic-ui-react';
import { validateDeviceCode } from '../models/Device';

export interface LinkDeviceButtonProps {
    errorPosition: 'top' | 'bottom';
    onSuccess?: (deviceId: string) => void;
    disabled?: boolean;
}

export function LinkDeviceButton({ errorPosition, onSuccess, disabled = false }: LinkDeviceButtonProps) {
    const { currentUser } = useAuth();

    const [setupCode, setSetupCode] = useState('');
    const [canShowSetupCodeValidationErrors, setCanShowSetupCodeValidationErrors] = useState(false);
    const setupCodeValidationErrors = validateDeviceCode(setupCode);
    const [setupCodeError, setSetupCodeError] = useState('');
    const [loading, setLoading] = useState(false);

    const errors: string[] = [];
    if (setupCodeValidationErrors && canShowSetupCodeValidationErrors) {
        errors.push(setupCodeValidationErrors);
    }
    if (setupCodeError) {
        errors.push(setupCodeError);
    }

    const deviceIdInputRef = useRef<Input | null>(null);

    const onDeviceCodeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const code = ev.target.value.toUpperCase();
        setSetupCode(code);
        if (code.length === 8) {
            setCanShowSetupCodeValidationErrors(true);
        }
    };

    const onBlur = () => {
        if (setupCode) {
            setCanShowSetupCodeValidationErrors(true);
        }
    };

    const onAddDevice = async (ev?: React.FormEvent<HTMLFormElement>) => {
        ev?.preventDefault();
        if (loading || disabled) {
            return;
        }

        if (setupCodeValidationErrors) {
            setCanShowSetupCodeValidationErrors(true);
            return;
        }

        setLoading(true);

        const token = await currentUser?.getIdToken() ?? '';
        try {
            const onAddResponse = await axios({
                method: 'POST',
                url: `/linkDevice/?code=${encodeURIComponent(setupCode)}`,
                headers: {
                    Authorization: `Bearer ${encodeURIComponent(token)}`,
                },
            });
            setSetupCode('');
            setCanShowSetupCodeValidationErrors(false);
            onSuccess?.(onAddResponse.data.deviceId);
        }
        catch (e) {
            if (axios.isAxiosError(e) && e.response?.status === 404) {
                setSetupCodeError('Incorrect device code entered');
            }
            else {
                setSetupCodeError('An unknown error occurred.');
            }
            deviceIdInputRef.current?.focus();
        }
        finally {
            setLoading(false);
        }
    };

    const errorMessage = (
        <Message
            error
            header="Error"
            content={(
                <List bulleted>
                    {errors.map(error => (
                        <ListItem key={error}>
                            {error}
                        </ListItem>
                    ))}
                </List>
            )}
        />
    );

    return (
        <Form error={errors.length > 0}>
            {errorPosition === 'top' && errorMessage}
            <Form.Group>
                <Form.Field error={errors.length > 0}>
                    <Input
                        type="text"
                        value={setupCode}
                        onChange={onDeviceCodeChange}
                        placeholder="Device code"
                        onBlur={onBlur}
                        maxLength={8}
                        ref={deviceIdInputRef}
                    />
                </Form.Field>
                {loading ? (
                    <Button disabled>
                        <Icon loading name="spinner" />
                        Linking...
                    </Button>
                ) : (
                    <Button disabled={disabled} onClick={() => onAddDevice()}>
                        <Icon name="chain" />
                        Link Device
                    </Button>
                )}
            </Form.Group>
            {errorPosition === 'bottom' && errorMessage}
        </Form>
    );
}
