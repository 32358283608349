import React from 'react';
import { Header } from 'semantic-ui-react';
import { LinkDeviceButton } from '../LinkDeviceButton';
import { DeviceSimOptions, ScreenSimulation } from '../ScreenSimulation';

export interface LinkStepProps {
    onDeviceLinked: (deviceId: string) => void;
    device: DeviceSimOptions;
    disabled: boolean;
}
export function LinkStep({ onDeviceLinked, device, disabled }: LinkStepProps) {
    return (
        <>
            <Header as="h2">Your device should show a Device Code similar to the following graphic:</Header>
            <ScreenSimulation line1="Device Code:" line2="ABC123" device={device} />
            <Header as="h3">Enter the code displayed on your device</Header>
            <LinkDeviceButton errorPosition="bottom" onSuccess={onDeviceLinked} disabled={disabled} />
        </>
    );
}
