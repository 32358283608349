import { onValue, query, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import { useDatabase } from 'reactfire';
import { UserDataModel } from '../models/UserDataModel';

export interface UserDisplayProps {
    userId: string;
    showId?: boolean;
}
export function UserDisplay({ userId, showId = false }: UserDisplayProps) {
    const [userData, setUserData] = useState<UserDataModel | null>(null);
    const databaseRef = useDatabase();

    useEffect(() => {
        onValue(
            query(ref(databaseRef, `users/${userId}`)),
            result => {
                setUserData(result.toJSON() as UserDataModel);
            },
        );
    }, [userId, databaseRef]);

    return (
        <span>
            {userData?.email}
            {showId && (
                <>
                    {' ('}
                    {userId}
                    {') '}
                </>
            )}
        </span>
    );
}
