import { UseFormFields } from '../utils/useForm';

export interface UserAccountModel {
    displayName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export const defaultFormValues: UserAccountModel = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
};

export const validateEmail = (email: string): string | null => {
    if (!email) {
        return 'Email is required';
    }

    if (!/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
        return 'Invalid email';
    }

    return null;
};

export const validatePassword = (password: string) : string | null => {
    if (!password) {
        return 'Password is required';
    }

    if (password.length < 8) {
        return 'Password must be at least 8 characters long';
    }

    if (!/[A-Z]/.test(password)) {
        return 'Password must contain at least one uppercase letter';
    }

    if (!/[a-z]/.test(password)) {
        return 'Password must contain at least one lowercase letter';
    }

    if (!/[0-9]/.test(password)) {
        return 'Password must contain at least one number';
    }

    if (!/[^A-Za-z0-9]/.test(password)) {
        return 'Password must contain at least one special character';
    }

    return null;
};

export const validateConfirmPassword = (confirmPassword: string, password: string | undefined): string | null => {
    if (!confirmPassword) {
        return 'Confirm password is required';
    }
    if (confirmPassword !== password) {
        return 'Passwords do not match';
    }

    return null;
};

export const signInFormFields: UseFormFields<UserAccountModel> = {
    email: validateEmail,
    password: (value: string) => {
        if (!value) {
            return 'Password is required';
        }

        return null;
    },
};

export const signUpFormFields: UseFormFields<UserAccountModel> = {
    ...signInFormFields,
    displayName: value => {
        if (!value) {
            return 'Display name is required';
        }

        return null;
    },
    password: validatePassword,
    confirmPassword: (confirm, data) => validateConfirmPassword(confirm, data.password),
};

export const forgotPasswordFormFields: UseFormFields<UserAccountModel> = {
    email: validateEmail,
};

export const resetPasswordFormFields: UseFormFields<UserAccountModel> = {
    password: validatePassword,
    confirmPassword: (confirm, data) => validateConfirmPassword(confirm, data.password),
};
