import React, { useCallback, useState } from 'react';
import {
    endBefore,
    orderByChild, orderByKey, query, ref, startAt,
} from 'firebase/database';
import { ErrorBoundary } from 'react-error-boundary';
import { SuspenseWithPerf, useDatabase } from 'reactfire';
import {
    Container, Input, Pagination, Table,
} from 'semantic-ui-react';
import { ErrorScreen } from '../../components/ErrorScreen';
import { DeviceList } from '../../components/DeviceList';
import { UserDataModel } from '../../models/UserDataModel';
import { usePaginated, UsePaginatedOptions } from '../../utils/usePaginated';

import styles from './ManageUsers.module.css';
import { RequireRole } from '../../components/RequireRole';
import { useDocumentTitle } from '../../utils/useDocumentTitle';

interface Sort {
    field: keyof UserDataModel;
    ascending: boolean;
}

function ManageUsersHelper() {
    useDocumentTitle('Admin - Manage Users');

    const databaseRef = useDatabase();

    const [sort, setSort] = useState<Sort>({ field: 'email', ascending: true });
    const [filter, setFilter] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<string | null>(null);

    const queryBuilder: UsePaginatedOptions<UserDataModel>['queryBuilder'] = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        constraints => {
            const cons = [...constraints];
            if (filter) {
                if (!cons.some(c => c.constructor.name.toLowerCase().includes('startat')
                    || c.constructor.name.toLowerCase().includes('startafter'))) {
                    cons.push(startAt(filter));
                }
                if (!cons.some(c => c.constructor.name.toLowerCase().includes('endat')
                    || c.constructor.name.toLowerCase().includes('endbefore'))) {
                    cons.push(endBefore(`${filter}\uf8ff`));
                }
            }
            return query(
                ref(databaseRef, 'users'),
                sort.field === 'id' ? orderByKey() : orderByChild(sort.field),
                ...cons,
            );
        },
        [databaseRef, sort, filter],
    );
    const {
        data: users, status, setPage, currentPage, knownTotalPages,
    } = usePaginated<UserDataModel>({
        pageSize: 10,
        sortKey: sort.field,
        reverse: !sort.ascending,
        queryBuilder,
    });

    const onSort = (sortBy: keyof UserDataModel) => {
        const newSort: Sort = {
            field: sortBy,
            ascending: sort.field === sortBy ? !sort.ascending : true,
        };
        setSort(newSort);
    };

    if (status === 'loading') {
        return null;
    }

    const sortDirection = sort.ascending ? 'ascending' : 'descending';
    const calcSortable = (field: keyof UserDataModel) => (sort.field === field ? sortDirection : undefined);

    return (
        <Container>
            <Input
                type="text"
                placeholder="search currently sorted column"
                fluid
                icon="search"
                iconPosition="left"
                value={filter}
                onChange={(e, { value }) => setFilter(value)}
            />
            <Table sortable compact selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell collapsing sorted={calcSortable('id')} onClick={() => onSort('id')}>
                            UserId
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={calcSortable('email')} onClick={() => onSort('email')}>
                            Email
                        </Table.HeaderCell>
                        <Table.HeaderCell sorted={calcSortable('role')} onClick={() => onSort('role')}>
                            Role
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users?.map(user => (
                        <Table.Row key={user.id} active={selectedUser === user.id} onClick={() => setSelectedUser(user.id)}>
                            <Table.Cell>
                                {user.id}
                            </Table.Cell>
                            <Table.Cell>
                                {user.email}
                            </Table.Cell>
                            <Table.Cell>
                                {user.role ?? 'user (unset)'}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Pagination activePage={currentPage} totalPages={knownTotalPages + 1} onPageChange={(_, data) => setPage(data.activePage as number)} />
            {selectedUser && (
                <div key={selectedUser} className={styles.userView}>
                    <DeviceList userId={selectedUser} />
                </div>
            )}
        </Container>
    );
}

export function ManageUsers() {
    return (
        <ErrorBoundary FallbackComponent={ErrorScreen}>
            <RequireRole requiredRole="admin">
                <SuspenseWithPerf fallback="loading..." traceId="admin-panel">
                    <ManageUsersHelper />
                </SuspenseWithPerf>
            </RequireRole>
        </ErrorBoundary>
    );
}
