export const validCurrencies = [
    { key: 'dollar', text: '$', value: 'dollar' },
    { key: 'pound', text: '£', value: 'pound' },
    { key: 'euro', text: '€', value: 'euro' },
    { key: 'yen', text: '¥', value: 'yen' },
];

export const validFormattings = [
    { key: 'magnitude', text: 'Magnitude (12.34, 1.23K, 12.3M, 123B)', value: 'magnitude' },
    { key: 'full', text: 'Full (12.34, 1,230.00, 12,300,000.00, 123,000,000,000.00)', value: 'full' },
];

export const deltaModes = [
    { key: 'lastClose', text: 'Change Since Last Market Closing (24h For Crypto)', value: 'lastClose' },
    { key: 'originalPurchasePrice', text: 'Change Since Original Purchase', value: 'originalPurchasePrice' },
];

export const brightnessSettings = [
    { key: 'off', text: 'Off', value: 0 },
    { key: 'night', text: 'Night time', value: 5 },
    { key: 'low', text: 'Low', value: 10 },
    { key: 'medium', text: 'Medium', value: 35 },
    { key: 'bright', text: 'Bright (Default)', value: 100 },
];

export const enabledDisabledOptions = [
    { key: 'false', text: 'Disabled', value: false },
    { key: 'true', text: 'Enabled', value: true },
];
