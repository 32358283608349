import React from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';

import instructions1 from '../../images/instructions/instructions-outlines-03.png';
import instructions2 from '../../images/instructions/instructions-outlines-04.png';
import instructions3 from '../../images/instructions/instructions-outlines-05.png';
import instructions4 from '../../images/instructions/instructions-outlines-06.png';
import instructions5 from '../../images/instructions/instructions-outlines-07.png';
import instructions6 from '../../images/instructions/instructions-outlines-08.png';
import instructions7 from '../../images/instructions/instructions-outlines-09.png';

import styles from './SetupGuide.module.css';

export interface DeviceOnlineStepProps {
    onNext: () => void;
}

type Instruction = {
    title: string;
    image?: string;
    // alt: string;
    paragraphs: (string | string[])[];
};

const instructions: Instruction[] = [
    {
        title: 'Attach the rocket',
        image: instructions1,
        paragraphs: [
            'With the device turned off, attach the rocket to the base of the device, and align the rocket to the 3:00 position.',
            'Note: you will need to align the rocket to this position every time you power-on the device.',
        ],
    },
    {
        title: 'Plug In & Power Up',
        image: instructions2,
        paragraphs: [
            'Plug in the device, and turn it on using the power switch located on the back.',
        ],
    },
    {
        title: 'Wi-Fi ID and Password',
        image: instructions3,
        paragraphs: [
            'The device will show a Wi-Fi network id and password.',
        ],
    },
    {
        title: 'Connect to Stock Rocket network',
        image: instructions4,
        paragraphs: [
            'On your phone, tablet, or computer:',
            [
                'Open your Wi-Fi settings.',
                'Locate the Stock Rocket Wi-Fi network.',
                'Select the network to connect.',
                'Enter the ID and Password from Step 3.',
                'Click "Connect"',
            ],
        ],
    },
    {
        title: 'Sign into the network',
        image: instructions5,
        paragraphs: [
            "When you're done with Step 4, you'll be prompted to sign into the network. Click the prompt to sign in.",
        ],
    },
    {
        title: 'Click "Configure WiFi"',
        image: instructions6,
        paragraphs: [],
    },
    {
        title: 'Sign into your home network',
        image: instructions7,
        paragraphs: [
            'Choose your home Wi-Fi network from the list and enter your password. Click "Save"',
        ],
    },
    {
        title: 'All set!',
        paragraphs: [
            'Your device is now online and should display a Device Code. '
            + 'Click "Next" to link your device to your account.',
        ],
    },
];

export function DeviceOnlineStep({ onNext }: DeviceOnlineStepProps) {
    return (
        <>
            <div className={styles.spacer} />
            <Button size="massive" primary circular className={styles.nextButton} onClick={onNext}>
                I&apos;ve already completed these steps.
                <Icon name="arrow right" />
            </Button>
            <div className={styles.spacer} />
            <div className={styles.hr} />
            {instructions.map((instruction, i) => (
                <>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <div className={styles.instructionCard} key={i}>
                        {/* eslint-disable-next-line jsx-a11y/alt-text */}
                        {instruction.image && <img className={styles.instructionImage} src={instruction.image} /> }
                        {!instruction.image && <div className={styles.instructionImage} /> }
                        <div className={styles.instructionCardText}>
                            <Header as="h3">
                                {i + 1}
                                .
                                {' '}
                                {instruction.title}
                            </Header>
                            {instruction.paragraphs.map((p, j) => {
                                if (typeof (p) === 'string') {
                                    return (
                                    // eslint-disable-next-line react/no-array-index-key
                                        <p key={j}>{p}</p>
                                    );
                                }
                                return (
                                    <ol>
                                        {p.map((item, k) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                            <li key={k}>{item}</li>
                                        ))}
                                    </ol>
                                );
                            })}
                        </div>
                    </div>
                    {i !== instructions.length - 1 && (
                        <div className={styles.hr} />
                    )}
                </>
            ))}
            <div className={styles.hr} />
            <div className={styles.spacer} />
            <Button size="massive" primary circular className={styles.nextButton} onClick={onNext}>
                Next
                <Icon name="arrow right" />
            </Button>
            <div className={styles.spacer} />
        </>
    );
}
