import { useCallback, useEffect, useState } from 'react';

export type UseTimedValueResult<T> = {
    value: T,
    isRunning: boolean,
    setValue: (value: T) => void,
};

export function useTimedValue<T>(defaultValue: T, timeout: number, onTimeout?: () => void): UseTimedValueResult<T> {
    const [value, setValue] = useState(defaultValue);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        if (isRunning) {
            setTimeout(() => {
                setIsRunning(false);
                setValue(defaultValue);
                if (onTimeout) {
                    onTimeout();
                }
            }, timeout);
        }
    }, [isRunning, timeout, defaultValue, onTimeout]);

    const setValueCallback = useCallback((v: T) => {
        setIsRunning(true);
        setValue(v);
    }, [setIsRunning, setValue]);

    return {
        value,
        isRunning,
        setValue: setValueCallback,
    };
}
