import React, { useCallback, useEffect, useState } from 'react';
import { useAuth, useUser } from 'reactfire';
import {
    createUserWithEmailAndPassword,
    sendEmailVerification, signInWithEmailAndPassword, updateProfile,
} from 'firebase/auth';
import {
    Divider, Form, Header, List, Message, Popup,
} from 'semantic-ui-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { ThirdPartySignIn, ThirdPartySignInProvider } from '../components/LoginForm/ThirdPartySignIn';
import styles from './LoginForm.module.css';
import { getFirebaseErrorMessage } from '../utils/firebaseErrorMappings';
import { useForm } from '../utils/useForm';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import {
    defaultFormValues, signInFormFields, signUpFormFields, UserAccountModel,
} from '../models/UserAccountModel';
import { useDocumentTitle } from '../utils/useDocumentTitle';

export interface LoginFormProps {
    mode?: 'sign in' | 'sign up';
}

export function LoginForm({ mode = 'sign in' }: LoginFormProps) {
    const auth = useAuth();
    const { data: user, status } = useUser();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect');
    const navigate = useNavigate();
    const [thirdPartySignInLoading, setThirdPartySignInLoading] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    useDocumentTitle(mode === 'sign in' ? 'Sign In' : 'Sign Up');

    const onLoginSuccess = useCallback(() => {
        window.location.href = redirectUrl || '/';
    }, [redirectUrl]);

    useEffect(() => {
        if (status === 'success' && user && !thirdPartySignInLoading) {
            onLoginSuccess();
        }
    }, [user, status, navigate, redirectUrl, onLoginSuccess, thirdPartySignInLoading]);

    const [loginError, setLoginError] = useState<string | null>(null);
    const onPasswordSignIn = async (data: Partial<UserAccountModel>) => {
        if (!data.email || !data.password) {
            return;
        }
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password);
            onLoginSuccess();
        }
        catch (error) {
            if (error instanceof FirebaseError) {
                setLoginError(getFirebaseErrorMessage(error.code));
            }
            console.error(error);
        }
    };

    const onPasswordSignUp = async (data: Partial<UserAccountModel>) => {
        if (!data.email || !data.password) {
            return;
        }
        try {
            const result = await createUserWithEmailAndPassword(auth, data.email, data.password);
            await Promise.all([
                sendEmailVerification(result.user),
                updateProfile(result.user, {
                    displayName: data.displayName,
                }),
            ]);
            // onLoginSuccess();
        }
        catch (error) {
            if (error instanceof FirebaseError) {
                setLoginError(getFirebaseErrorMessage(error.code));
            }
            console.error(error);
        }
    };

    const {
        isSaving: signInLoading,
        clear,
        getInputProps,
        submit,
    } = useForm({
        fields: mode === 'sign in' ? signInFormFields : signUpFormFields,
        initialValue: defaultFormValues,
        enableAllValidationOnSave: true,
        onSave: mode === 'sign in' ? onPasswordSignIn : onPasswordSignUp,
    });

    useEffect(() => {
        clear();
        setLoginError(null);
    }, [mode, clear]);

    const onThirdPartySignInStarted = useCallback(() => {
        setThirdPartySignInLoading(true);
    }, []);

    const onThirdPartySignInError = useCallback(async (providerName: ThirdPartySignInProvider, error: unknown) => {
        setThirdPartySignInLoading(false);
        if (error instanceof FirebaseError) {
            setLoginError(getFirebaseErrorMessage(error.code));
        }
        console.error(error);
    }, []);

    const errorMessages: string[] = [];
    if (loginError) {
        errorMessages.push(loginError);
    }
    const hasServerError = errorMessages.length > 0;

    const signUpUrl = `/sign-up${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;
    const signInUrl = `/sign-in${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;
    const forgotPasswordUrl = `/forgot-password${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;

    return (
        <LoginContainer loading={signInLoading}>
            {mode === 'sign in' && (
                <>
                    <Header as="h1">
                        Sign in
                    </Header>
                    <ThirdPartySignIn
                        onSignIn={onLoginSuccess}
                        onSignInFailed={onThirdPartySignInError}
                        onSignInStarted={onThirdPartySignInStarted}
                        disabled={signInLoading}
                    />
                    <Divider horizontal>
                        or use your email address and password
                    </Divider>
                    <Form error={hasServerError}>
                        <Message
                            error
                            style={{ textAlign: 'left' }}
                            content={(
                                <List bulleted>
                                    {errorMessages.map(errorMessage => (
                                        <List.Item key={errorMessage}>
                                            {errorMessage}
                                        </List.Item>
                                    ))}
                                </List>
                            )}
                        />
                        <Form.Input
                            type="text"
                            placeholder="Email address"
                            icon="mail"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('email')}
                        />
                        <Form.Input
                            type="password"
                            placeholder="Password"
                            icon="lock"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('password')}
                        />
                        <p>
                            <Link to={signUpUrl}>
                                Don&apos;t have an account?
                            </Link>
                        </p>
                        <p>
                            <Link to={forgotPasswordUrl}>
                                Forgot your password?
                            </Link>
                        </p>
                        <Form.Button
                            primary
                            fluid
                            content="Sign in"
                            onClick={() => submit()}
                            loading={signInLoading}
                        />
                    </Form>

                </>
            )}
            {mode === 'sign up' && (
                <>
                    <Header as="h1">
                        Create account
                    </Header>
                    <ThirdPartySignIn
                        onSignIn={onLoginSuccess}
                        onSignInFailed={onThirdPartySignInError}
                        onSignInStarted={onThirdPartySignInStarted}
                        disabled={signInLoading}
                    />
                    <Divider horizontal>
                        or register with your email address
                    </Divider>
                    <Form error={hasServerError}>
                        <Message
                            error
                            style={{ textAlign: 'left' }}
                            content={(
                                <List bulleted>
                                    {errorMessages.map(errorMessage => (
                                        <List.Item key={errorMessage}>
                                            {errorMessage}
                                        </List.Item>
                                    ))}
                                </List>
                            )}
                        />
                        <Form.Input
                            type="text"
                            placeholder="Display name"
                            icon="users"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('displayName')}
                        />
                        <Form.Input
                            type="text"
                            placeholder="Email address"
                            icon="mail"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('email')}
                        />
                        <Form.Input
                            type="password"
                            placeholder="Password"
                            icon="lock"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('password')}
                        />
                        <Form.Input
                            type="password"
                            placeholder="Confirm password"
                            icon="lock"
                            iconPosition="left"
                            className={styles.formField}
                            {...getInputProps('confirmPassword')}
                        />
                        <Form.Checkbox
                            label={{
                                children: (
                                    <span>
                                        I have read and accept the
                                        {' '}
                                        <Link to="/terms" target="_blank">Terms and Conditions</Link>
                                        {' '}
                                        and
                                        {' '}
                                        <Link to="/privacy" target="_blank">Privacy Policy</Link>
                                    </span>
                                ),
                            }}
                            checked={acceptedTerms}
                            onChange={() => setAcceptedTerms(!acceptedTerms)}
                        />
                        <p>
                            <Link to={signInUrl}>
                                Already have an account?
                            </Link>
                        </p>
                        <Popup
                            content="You must accept the terms and conditions to create an account."
                            position="top center"
                            disabled={acceptedTerms}
                            flowing
                            trigger={(
                                <span>
                                    <Form.Button
                                        primary
                                        fluid
                                        content="Create account"
                                        onClick={() => submit()}
                                        loading={signInLoading}
                                        disabled={!acceptedTerms}
                                    />
                                </span>
                            )}
                        />
                    </Form>
                </>
            )}
        </LoginContainer>
    );
}
