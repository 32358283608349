import React from 'react';
import {
    Button,
    Grid, Header, Icon, Segment, Step,
} from 'semantic-ui-react';
import { DeviceSimOptions } from '../ScreenSimulation';
import { DeviceOnlineStep } from './DeviceOnlineStep';
import { LinkStep } from './LinkStep';
import { PortfolioStep } from './PortfolioStep';

import styles from './SetupGuide.module.css';

const sampleDevice: DeviceSimOptions = {
    displaySymbol: 'BTC',
    hardwareVersion: 'v7.4.0',
    brightness: 100,
    contrast: 100,
    currency: 'dollar',
    deltaMode: 'lastClose',
    enablePostMarket: false,
    formatting: 'magnitude',
    shouldDisplayPrices: true,
};

export function SetupGuide() {
    const [currentPage, setCurrentPage] = React.useState(0);
    const [deviceId, setDeviceId] = React.useState<string | null>(null);
    const [progress, setProgress] = React.useState(0);

    const canIncrementPage = (currentPage < 2 && progress >= currentPage + 1) || (currentPage === 0);

    const gotoDeviceOnlineStep = () => {
        setCurrentPage(0);
    };

    const gotoLinkStep = () => {
        setCurrentPage(1);
        setProgress(Math.max(progress, 1));
    };

    const gotoPortfolioStep = () => {
        setCurrentPage(2);
        setProgress(Math.max(progress, 2));
    };

    const gotoPrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const gotoNextPage = () => {
        if (canIncrementPage) {
            setCurrentPage(currentPage + 1);
            setProgress(Math.max(progress, currentPage + 1));
        }
    };

    const onDeviceLinked = (id: string) => {
        setDeviceId(id);
        gotoPortfolioStep();
    };

    let content: JSX.Element;
    switch (currentPage) {
        case 0:
            content = <DeviceOnlineStep onNext={gotoLinkStep} />;
            break;
        case 1:
            content = <LinkStep device={sampleDevice} onDeviceLinked={onDeviceLinked} disabled={!!deviceId} />;
            break;
        case 2:
            if (deviceId === null) {
                throw new Error('Device ID is null');
            }
            content = <PortfolioStep deviceId={deviceId} />;
            break;
        default:
            content = <div />;
            break;
    }

    return (
        <Grid>
            <Grid.Row centered>
                <Grid.Column width={16}>
                    <Header as="h1">Congratulations on your new StockRocket!</Header>
                    <p>
                        Let&apos;s get your device connected to the internet and configured.
                        Don&apos;t have one?
                        {' '}
                        <a href="https://stockrocket.us/products/stock-rocket" target="_blank" rel="noreferrer">
                            Buy one here!
                        </a>
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
                <Grid.Column width={16} className={styles.steps}>
                    <Step.Group size="small" attached="top" widths={4}>
                        <Button.Group>
                            <Button disabled={currentPage === 0} onClick={gotoPrevPage} className={styles.navButton}>
                                <Icon name="arrow left" />
                                prev
                            </Button>
                        </Button.Group>
                        <Step active={currentPage !== 0} onClick={gotoDeviceOnlineStep} completed={progress >= 1}>
                            <Icon name="wifi" />
                            <Step.Content>
                                <Step.Title>1 - Get your device online</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step active={currentPage !== 1} onClick={gotoLinkStep} completed={progress >= 2}>
                            <Icon name="chain" />
                            <Step.Content>
                                <Step.Title>2 - Link your device</Step.Title>
                            </Step.Content>
                        </Step>
                        <Step disabled={deviceId === null} active={currentPage !== 2} onClick={gotoPortfolioStep} completed={progress >= 3}>
                            <Icon name="cog" />
                            <Step.Content>
                                <Step.Title>3 - Basic setup</Step.Title>
                            </Step.Content>
                        </Step>
                        <Button.Group>
                            <Button disabled={!canIncrementPage} onClick={gotoNextPage} className={styles.navButton}>
                                next
                                <Icon name="arrow right" />
                            </Button>
                        </Button.Group>
                    </Step.Group>
                    <Segment>
                        {content}
                    </Segment>
                    <div className={styles.bottomMargin} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
