import React, {
    useState, useImperativeHandle, forwardRef, useRef,
} from 'react';
import { Input, Search } from 'semantic-ui-react';
import stringSimilarity from 'string-similarity';
import { SymbolInfo } from '../utils/types';
import { useStockData } from './StockDataProvider';

function filterSymbols(symbols: { [key: string]: SymbolInfo }, filter: string): SymbolInfo[] {
    const results: (SymbolInfo & { similarity: number })[] = [];

    const f = filter.toLowerCase();

    Object.keys(symbols).forEach(k => {
        const s = symbols[k];
        const symbol = s.symbol.toLowerCase();
        const name = s.name.toLowerCase();
        if (symbol.startsWith(f) || name.startsWith(f)) {
            results.push({
                ...s,
                similarity: (1 - stringSimilarity.compareTwoStrings(f, symbol)) * (1 - stringSimilarity.compareTwoStrings(f, name)),
            });
        }
    });

    results.sort((a, b) => a.similarity - b.similarity);
    return results.slice(0, 10);
}

export interface StockPickerProps {
    onSelect?: (symbolId: string | null) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    className?: string;
    transparent?: boolean;
    placeholder?: string;
    error?: boolean;
    disabled?: boolean;
    symbolFilter?: string[];
}

export interface StockPickerRef {
    clear: () => void;
    focus: () => void;
}

export const StockPicker = forwardRef(({
    onSelect,
    onKeyPress,
    onBlur,
    className,
    transparent = false,
    placeholder = 'Start typing to search for a stock or cryptocurrency',
    error = false,
    disabled = false,
    symbolFilter,
}: StockPickerProps, ref: React.ForwardedRef<StockPickerRef>) => {
    const { symbols: symbolsRaw } = useStockData();
    let symbols: typeof symbolsRaw;
    if (symbolFilter) {
        symbols = Object.keys(symbolsRaw).reduce((acc, k) => {
            if (symbolFilter.includes(symbolsRaw[k].uniqId)) {
                acc[k] = symbolsRaw[k];
            }
            return acc;
        }, {} as typeof symbolsRaw);
    }
    else {
        symbols = symbolsRaw;
    }

    const [searchText, setSearchText] = useState('');
    const [lastHighlighted, setLastHighlighted] = useState('');

    const inputRef = useRef<Input | null>(null);

    useImperativeHandle(ref, () => ({
        clear: () => {
            setSearchText('');
            setLastHighlighted('');
        },
        focus: () => {
            inputRef.current?.focus();
        },
    }));

    const onSearchSelected = (id: string) => {
        setSearchText(symbols[id].symbol);
        setLastHighlighted(id);
        if (onSelect) {
            onSelect(id);
        }
    };
    const onHighlight = (selectionUniqId: string) => {
        setLastHighlighted(selectionUniqId);
    };
    const onSearchBlur = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (onBlur) {
            onBlur(ev);
        }
        if (searchText.length > 0) {
            if (lastHighlighted) {
                onSearchSelected(lastHighlighted);
                return;
            }

            const [topResult] = filterSymbols(symbols, searchText);
            if (topResult) {
                onSearchSelected(topResult.uniqId);
            }
        }
    };
    const onSearchChange = (text: string) => {
        setSearchText(text);
        setLastHighlighted('');
        if (onSelect) {
            onSelect(null);
        }
    };

    return (
        <Search
            placeholder={placeholder}
            className={className}
            value={searchText}
            onSearchChange={(ev, data) => onSearchChange(data.value ?? '')}
            onResultSelect={(ev, data) => onSearchSelected(data.result.id)}
            onSelectionChange={(ev, data) => onHighlight(data.result.id)}
            onBlur={onSearchBlur}
            onKeyPress={onKeyPress}
            results={filterSymbols(symbols, searchText)
                .map(s => ({ title: s.symbol, description: s.name, id: s.uniqId }))}
            icon={null}
            input={{
                transparent,
                fluid: true,
                ref: inputRef,
                error,
            }}
            fluid
            selectFirstResult
            autoComplete="off"
            disabled={disabled}
            minCharacters={symbolFilter === undefined ? 1 : 0}
            noResultsMessage={symbolFilter === undefined ? 'No results found.' : 'You have reached the maximum number of symbols for this device.'}
        />
    );
});
