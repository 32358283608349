import React, { useEffect, useState } from 'react';
import {
    applyActionCode,
    confirmPasswordReset, signInWithEmailAndPassword, verifyPasswordResetCode,
} from 'firebase/auth';
import { useLocation, Link } from 'react-router-dom';
import { useAuth } from 'reactfire';
import {
    Form, Header, Message,
} from 'semantic-ui-react';
import { FirebaseError } from 'firebase/app';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import {
    defaultFormValues, resetPasswordFormFields, UserAccountModel,
} from '../models/UserAccountModel';
import { getFirebaseErrorMessage } from '../utils/firebaseErrorMappings';
import { useForm } from '../utils/useForm';
import { useTimedValue } from '../utils/useTimedValue';
import styles from './LoginForm.module.css';
import { useDocumentTitle } from '../utils/useDocumentTitle';

interface ActionProps {
    actionCode: string;
    continueUrl: string;
}

function EmailVerificationAction({ actionCode, continueUrl }: ActionProps) {
    useDocumentTitle('Email Verification');
    const auth = useAuth();
    const [isCodeValid, setIsCodeValid] = useState<null | boolean>(null);

    // const [sendError, setSendError] = useState<string | null>(null);

    useEffect(() => {
        if (!actionCode) {
            setIsCodeValid(false);
            return;
        }
        setIsCodeValid(null);
        applyActionCode(auth, actionCode).then(() => {
            setIsCodeValid(true);
            window.location.href = continueUrl;
        }).catch(e => {
            setIsCodeValid(false);
            console.error(e);
            if (e instanceof FirebaseError) {
                // setSendError(getFirebaseErrorMessage(e.code));
            }
        });
    }, [auth, actionCode, continueUrl]);

    if (isCodeValid === false) {
        return (
            <LoginContainer textAlign="left">
                <Header as="h1">Error</Header>
                <p>The link is invalid or has expired. Please make sure you copied the link correctly.</p>
                <p><Link to="/">Back to home page</Link></p>
            </LoginContainer>
        );
    }

    return (
        <LoginContainer textAlign="left" loading={isCodeValid === null}>
            <Header as="h1">Verifying Email Address</Header>
            <p>Please wait.</p>
        </LoginContainer>
    );
}

function PasswordResetAction({
    actionCode,
    continueUrl,
}: ActionProps) {
    useDocumentTitle('Password reset');
    const auth = useAuth();
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [isCodeValid, setIsCodeValid] = useState<null | boolean>(null);

    const {
        value: submitButtonValue,
        isRunning: submitButtonValueAlt,
        setValue: setSubmitButtonValue,
    } = useTimedValue('Change password', 5000);
    const [sendError, setSendError] = useState<string | null>(null);

    useEffect(() => {
        if (!actionCode) {
            setIsCodeValid(false);
            return;
        }
        setIsCodeValid(null);
        setUserEmail(null);
        verifyPasswordResetCode(auth, actionCode).then(resultEmail => {
            setIsCodeValid(true);
            setUserEmail(resultEmail);
        }).catch(error => {
            setIsCodeValid(false);
            console.error(error);
        });
    }, [auth, actionCode, setSubmitButtonValue]);

    const onSave = async (data: Partial<UserAccountModel>) => {
        if (!data.password || !userEmail) {
            return;
        }

        try {
            await confirmPasswordReset(auth, actionCode, data.password);
            await signInWithEmailAndPassword(auth, userEmail, data.password);
            setSubmitButtonValue('Password updated');
            window.location.href = continueUrl;
        }
        catch (e) {
            console.error(e);
            if (e instanceof FirebaseError) {
                setSubmitButtonValue('Error resetting password');
                setSendError(getFirebaseErrorMessage(e.code));
            }
        }
    };

    const {
        isSaving,
        getInputProps,
        submit,
    } = useForm({
        fields: resetPasswordFormFields,
        initialValue: defaultFormValues,
        enableAllValidationOnSave: true,
        onSave,
    });

    if (isCodeValid === false) {
        return (
            <LoginContainer textAlign="left">
                <Header as="h1">Error</Header>
                <p>The link is invalid or has expired. Please make sure you copied the link correctly.</p>
                <p><Link to="/forgot-password">Back to reset password</Link></p>
            </LoginContainer>
        );
    }

    return (
        <LoginContainer textAlign="left" loading={isCodeValid === null}>
            <Header as="h1">Reset Password</Header>
            <p>Enter a new password.</p>
            <Form error={!!sendError}>
                <Message
                    error
                    content={sendError}
                />
                <Form.Input
                    type="password"
                    placeholder="Password"
                    icon="lock"
                    iconPosition="left"
                    className={styles.formField}
                    {...getInputProps('password')}
                />
                <Form.Input
                    type="password"
                    placeholder="Confirm password"
                    icon="lock"
                    iconPosition="left"
                    className={styles.formField}
                    {...getInputProps('confirmPassword')}
                />
                <Form.Button
                    primary
                    fluid
                    loading={isSaving}
                    content={submitButtonValue}
                    onClick={() => submit()}
                    disabled={submitButtonValueAlt || isSaving}
                />
            </Form>
        </LoginContainer>
    );
}

export function EmailActions() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const mode = searchParams.get('mode');
    const oobCode = searchParams.get('oobCode');
    // const apiKey = searchParams.get('apiKey');
    // const lang = searchParams.get('lang');
    const continueUrl = searchParams.get('continueUrl');

    const actionProps: ActionProps = {
        actionCode: oobCode || '',
        continueUrl: continueUrl || '/',
    };

    if (mode === 'resetPassword') {
        return <PasswordResetAction {...actionProps} />;
    }

    if (mode === 'verifyEmail') {
        return <EmailVerificationAction {...actionProps} />;
    }

    return null;
}
