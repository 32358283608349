import { UseFormFields } from '../utils/useForm';

export interface PortfolioItem {
    shares: string; // TODO: number
    symbol: string;
    originalPurchasePrice: string; // TODO: number
}

export interface Device {
    id: string;
    nickname: string;
    ownerUid: string;
    dateLinked: string;
    displaySymbol?: string;
    currency?: 'dollar' | 'euro' | 'yen' | 'pound';
    formatting?: 'magnitude' | 'full';
    deltaMode?: 'lastClose' | 'originalPurchasePrice';
    portfolio?: { [id: string]: PortfolioItem };
    enablePostMarket?: boolean;
    shouldDisplayPrices?: boolean;
    brightness?: number;
    contrast?: number;

    hardwareVersion: string;
    macAddress: string;
    latestKnownVersion: string;
    explicitVersionOverride: string;
    enablePreviewBuilds?: boolean;
    disableAutoUpdates?: boolean;
    useDebugBackend?: string;
}

export function validateDeviceCode(value?: string): string | null {
    if (!value) {
        return 'Enter the code displayed on the device';
    }
    if (!/^[a-zA-Z0-9]*$/.test(value)) {
        return 'The code should only contain numbers and uppercase letters';
    }
    if (value.length === 8) {
        return 'The code is 6 characters long. '
        + 'If you are seeing an 8 character code on the screen, '
        + 'it likely means the device is not connected to a wireless network. '
        + 'Go back to the "Get your device online" page and follow the steps to '
        + 'get connected to a wireless network.';
    }
    if (value.length !== 6) {
        return 'The code is 6 characters long';
    }
    return null;
}

export function validateDisplaySymbol(value?: string): string | null {
    if (!value) {
        return 'A nickname is required';
    }
    if (value.length > 20) {
        return 'Must be 20 characters or less';
    }
    if (!/^[\w -]+$/.test(value)) {
        return 'Only letters, numbers, hyphens, spaces, and underscores are allowed';
    }
    return null;
}

export const DeviceFields: UseFormFields<Device> = {
    displaySymbol: validateDisplaySymbol,
};

export const PortfolioItemFields: UseFormFields<PortfolioItem> = {
    shares: value => {
        if (!value || parseFloat(value) <= 0) {
            return 'You must enter a positive number of shares';
        }

        if (/[^\d.,]/.test(value)) {
            return 'Please enter a valid number';
        }

        return null;
    },
    originalPurchasePrice: value => {
        if (!value || parseFloat(value) <= 0) {
            return 'You must enter a positive dollar amount';
        }

        if (/[^\d.,$]/.test(value)) {
            return 'Please enter a valid number';
        }

        return null;
    },
    symbol: value => {
        if (!value) {
            return 'You must select a valid symbol';
        }

        return null;
    },
};
