import React, { useState } from 'react';
import {
    ref, update,
} from 'firebase/database';
import { useDatabase } from 'reactfire';
import {
    Accordion,
    CheckboxProps, DropdownProps, Form, Icon, Input, InputOnChangeData, Label, Segment,
} from 'semantic-ui-react';

import { supportsHardwareBrightnessContrast } from '../featureGating';
import {
    deltaModes, validCurrencies, validFormattings, brightnessSettings, enabledDisabledOptions,
} from '../utils/constants';
import { Device, DeviceFields } from '../models/Device';
import { UnlinkDeviceButton } from './UnlinkDeviceButton';
import { useForm } from '../utils/useForm';
import { isDevelopment } from '../utils/isDevelopment';

import styles from './DeviceSettingsForm.module.css';

const defaultDevice: Device = {
    id: '',
    nickname: '',
    ownerUid: '',
    dateLinked: '',
    displaySymbol: '',
    currency: 'dollar',
    formatting: 'magnitude',
    deltaMode: 'lastClose',
    portfolio: { },
    enablePostMarket: false,
    shouldDisplayPrices: true,
    brightness: 100,
    contrast: 50,

    hardwareVersion: '',
    macAddress: '',
    latestKnownVersion: '',
    explicitVersionOverride: '',
    enablePreviewBuilds: false,
    disableAutoUpdates: false,
    // useDebugBackend: null,
};

export interface DeviceSettingsProps {
    device: Device,
    onClose: () => void,
}
export function DeviceSettingsForm({ device, onClose }: DeviceSettingsProps) {
    const deviceRef = ref(useDatabase(), `devices/${device.id}`);
    const [isDebugExpanded, setIsDebugExpanded] = useState(false);

    const {
        errors,
        data: mergedChanges,
        update: onFormChange,
    } = useForm({
        initialValue: defaultDevice,
        sourceData: device,
        autosaveInterval: 100,
        fields: DeviceFields,
        onSave: async data => {
            await update(deviceRef, data);
        },
    });

    const onChange = async (ev: unknown, { value, checked, name }: InputOnChangeData | DropdownProps | CheckboxProps) => {
        if (name === 'displaySymbol') {
            onFormChange({
                displaySymbol: value?.toString(),
                nickname: value?.toString(),
            });
        }
        if (name === 'disableAutoUpdates') {
            onFormChange({
                disableAutoUpdates: !value,
            });
        }
        else {
            onFormChange({
                [name]: value !== undefined ? value : checked,
            });
        }
    };

    const onChangeNumber = async (ev: unknown, { value, name }: InputOnChangeData) => {
        await update(deviceRef, {
            [name]: value !== undefined ? parseFloat(value) : 50,
        });
    };

    const supportsBrightnessContrast = supportsHardwareBrightnessContrast(device.hardwareVersion);

    return (
        <Form>
            {isDevelopment() && (
                <Accordion className={styles.collapsibleFormSection}>
                    <Accordion.Title active={isDebugExpanded} onClick={() => setIsDebugExpanded(!isDebugExpanded)}>
                        <Icon name="dropdown" />
                        Debug Information (this section does not appear in production)
                    </Accordion.Title>
                    <Accordion.Content active={isDebugExpanded}>
                        <Segment>
                            <Form.Input
                                label="Id"
                                value={device.id}
                                name="id"
                                readOnly
                            />
                            <Form.Input
                                label="Date Linked"
                                value={new Date(device.dateLinked).toLocaleString()}
                                name="dateLinked"
                                readOnly
                            />
                            <Form.Input
                                label="Mac Address"
                                value={device.macAddress || ''}
                                name="macAddress"
                                readOnly
                            />
                            <Form.Input
                                label="Hardware Version"
                                value={device.hardwareVersion || ''}
                                name="hardwareVersion"
                                readOnly
                                placeholder="legacy"
                            />
                            <Form.Input
                                label="Target Firmware Version"
                                value={mergedChanges.explicitVersionOverride || ''}
                                name="explicitVersionOverride"
                                id={`${device.id}-explicitVersionOverride`}
                                onChange={onChange}
                                placeholder={device.latestKnownVersion}
                            />
                            <Form.Input
                                label="Firmware Version"
                                value={device.latestKnownVersion || ''}
                                name="latestKnownVersion"
                                readOnly
                            />
                            <Form.Select
                                label="Enable Preview Builds"
                                value={mergedChanges.enablePreviewBuilds}
                                name="enablePreviewBuilds"
                                options={enabledDisabledOptions}
                                onChange={onChange}
                                autoComplete="off"
                            />
                            <Form.Select
                                label="Enable Auto Updates"
                                value={!mergedChanges.disableAutoUpdates}
                                name="disableAutoUpdates"
                                options={enabledDisabledOptions}
                                onChange={onChange}
                                autoComplete="off"
                            />
                            <Form.Input
                                label="Debug Backend"
                                value={mergedChanges.useDebugBackend || ''}
                                name="useDebugBackend"
                                id={`${device.id}-useDebugBackend`}
                                onChange={onChange}
                                placeholder={device.useDebugBackend}
                            />
                        </Segment>
                    </Accordion.Content>
                </Accordion>
            )}
            <Form.Field error={!!errors.displaySymbol}>
                <label htmlFor={`${device.id}-nickname`}>
                    Device nickname
                    <Input
                        placeholder="nickname"
                        value={mergedChanges.displaySymbol}
                        name="displaySymbol"
                        id={`${device.id}-nickname`}
                        onChange={onChange}
                        maxLength={20}
                        autoFocus
                        autoComplete="off"
                    />
                </label>
                {errors.displaySymbol && (
                    <Label pointing prompt>
                        {errors.displaySymbol}
                    </Label>
                )}
            </Form.Field>
            <Form.Select
                label="Delta calculations"
                value={mergedChanges.deltaMode}
                name="deltaMode"
                options={deltaModes}
                onChange={onChange}
                autoComplete="off"
            />
            <Form.Checkbox
                label="Enable post-market price calculations"
                checked={mergedChanges.enablePostMarket}
                name="enablePostMarket"
                onChange={onChange}
                autoComplete="off"
            />
            <Form.Checkbox
                label="Display prices on device"
                checked={mergedChanges.shouldDisplayPrices}
                name="shouldDisplayPrices"
                onChange={onChange}
                autoComplete="off"
            />
            {mergedChanges.shouldDisplayPrices && (
                <Form.Group>
                    <Form.Select
                        width={4}
                        label="Currency (cosmetic only)"
                        value={mergedChanges.currency}
                        name="currency"
                        options={validCurrencies}
                        onChange={onChange}
                        style={{ minWidth: '1em' }}
                        autoComplete="off"
                    />
                    <Form.Select
                        width={12}
                        label="Formatting"
                        value={mergedChanges.formatting}
                        name="formatting"
                        options={validFormattings}
                        onChange={onChange}
                        autoComplete="off"
                    />
                </Form.Group>
            )}
            {supportsBrightnessContrast && (
                <>
                    <Form.Group>
                        <Form.Select
                            width={16}
                            label="Brightness"
                            value={mergedChanges.brightness ?? 100}
                            name="brightness"
                            options={brightnessSettings}
                            onChange={onChange}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Input
                            type="number"
                            step="5"
                            min="0"
                            max="100"
                            width={16}
                            label="Contrast"
                            value={mergedChanges.contrast ?? 50}
                            name="contrast"
                            onChange={onChangeNumber}
                            autoComplete="off"
                        />
                    </Form.Group>
                </>
            )}
            {/* <h3>Preview (not live data): </h3>
            <ScreenSimulation device={device} /> */}
            <Form.Group>
                <Form.Button onClick={() => onClose()}>
                    Done Editing Settings
                </Form.Button>
                <UnlinkDeviceButton deviceId={device.id} />
            </Form.Group>
        </Form>
    );
}
