import { User } from 'firebase/auth';
import { ref } from 'firebase/database';
import React from 'react';
import { useDatabase, useDatabaseObjectData, useUser } from 'reactfire';
import { UserDataModel } from '../models/UserDataModel';
import { NotFound } from '../pages/NotFound';
import { DeviceListPlaceholder } from './DeviceListPlaceholder';

export interface RequireRoleProps {
    requiredRole: string;
    children: React.ReactNode;
}

function RequireRoleHelper({ requiredRole, children, user }: RequireRoleProps & { user: User }) {
    const userDataRef = ref(useDatabase(), `users/${user.uid}`);
    const { data: userData, status } = useDatabaseObjectData<UserDataModel>(userDataRef, { idField: 'id' });

    if (status !== 'success') {
        return <DeviceListPlaceholder />;
    }

    if (userData?.role !== requiredRole) {
        return (
            <NotFound />
        );
    }

    return (
        <div>
            {children}
        </div>
    );
}

export function RequireRole({ requiredRole: role, children }: RequireRoleProps) {
    const { data: user, status } = useUser();

    if (status === 'loading' || !user) {
        return null;
    }

    if (status === 'error') {
        // TODO: navigate
    }

    return (
        <RequireRoleHelper requiredRole={role} user={user}>
            {children}
        </RequireRoleHelper>
    );
}
