import React from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import {
    useFirebaseApp, AuthProvider, DatabaseProvider, StorageProvider, FirebaseAppProvider,
} from 'reactfire';
import { StockDataProvider } from '../components/StockDataProvider';
import { FirebaseConfig } from '../FirebaseConfig';

function ProviderHelpers({ children } : { children: React.ReactNode }) {
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);
    const dbInstance = getDatabase(firebaseApp);
    const storageInstance = getStorage(firebaseApp);

    return (
        <AuthProvider sdk={auth}>
            <DatabaseProvider sdk={dbInstance}>
                <StorageProvider sdk={storageInstance}>
                    <StockDataProvider>
                        {children}
                    </StockDataProvider>
                </StorageProvider>
            </DatabaseProvider>
        </AuthProvider>
    );
}

export function FirebaseProvider({ children } : { children: React.ReactNode }) {
    return (
        <FirebaseAppProvider firebaseConfig={FirebaseConfig}>
            <ProviderHelpers>
                {children}
            </ProviderHelpers>
        </FirebaseAppProvider>
    );
}
