import React, { useEffect } from 'react';
import {
    Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { useUser } from 'reactfire';
import { WelcomePage } from './pages/WelcomePage';
import { NavBar } from './components/NavBar';
import { Dashboard } from './pages/Dashboard';

import './App.css';
import { LoginForm } from './pages/LoginForm';
import { DeviceListPlaceholder } from './components/DeviceListPlaceholder';
import { EmailVerification } from './pages/EmailVerification';
import { ForgotPassword } from './pages/ForgotPassword';
import { EmailActions } from './pages/EmailActions';
import { NotFound } from './pages/NotFound';
import { ManageUsers } from './pages/admin/ManageUsers';
import { Feedback } from './pages/admin/Feedback';
import { Footer } from './components/Footer';
import { TermsOfServiceModal } from './components/TermsOfService';
import { TosPage } from './pages/TosPage';
import { PrivacyPage } from './pages/PrivacyPage';

const unauthRoutes = [
    '/sign-in',
    '/sign-up',
    '/forgot-password',
    '/email-actions',
    '/terms',
    '/privacy',
];

export function App() {
    const { data: user, status } = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    // const currentUserRef = useRef<boolean | null>(null);

    useEffect(() => {
        if (status === 'success' && user === null && !unauthRoutes.some(r => location.pathname.startsWith(r))) {
            navigate(`/sign-in?redirect=${encodeURIComponent(window.location.href)}`);
        }
    }, [user, status, navigate, location.pathname]);

    const isWaitingForEmailVerification = status === 'success' && user?.emailVerified === false;
    useEffect(() => {
        if (isWaitingForEmailVerification) {
            const timer = setInterval(() => {
                user?.reload();
            }, 500);

            return () => {
                clearInterval(timer);
            };
        }
        return () => {};
    }, [user, isWaitingForEmailVerification]);

    if (status === 'loading' || user === undefined) {
        return (
            <>
                <NavBar />
                <DeviceListPlaceholder />
            </>
        );
    }

    if (isWaitingForEmailVerification && !location.pathname.startsWith('/email-actions')) {
        return (
            <>
                <NavBar />
                <EmailVerification />
            </>
        );
    }

    return (
        <>
            <NavBar />
            {user && (
                <TermsOfServiceModal user={user} />
            )}
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/sign-in" element={<LoginForm mode="sign in" />} />
                <Route path="/sign-up" element={<LoginForm mode="sign up" />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/email-actions" element={<EmailActions />} />
                <Route path="/welcome" element={<WelcomePage />} />
                <Route path="/terms" element={<TosPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/admin/users" element={<ManageUsers />} />
                <Route path="/admin/feedback" element={<Feedback />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </>
    );
}
