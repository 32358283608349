import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { FormInputProps, FormSelectProps, FormTextAreaProps } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useStateAsync } from './useStateAsync';

export interface RenderFieldOptions<T> {
    field: keyof T;
    placeholder?: string;
}

type FieldValidator<T, TKey> = (value: TKey, data: Partial<T>) => string | null;

export interface UseFormFieldConfig<T, TKey> {
    validator: FieldValidator<T, TKey>;
}

export type UseFormFields<T> = {
    [key in keyof T]?: UseFormFieldConfig<T, T[key]> | FieldValidator<T, T[key]>;
};

type ValidationErrors<T> = {
    [key in keyof T]?: string;
};

export interface UseFormConfig<T> {
    initialValue: T;
    sourceData?: T;
    autosaveInterval?: number;
    validateAllFields?: boolean;
    fields?: UseFormFields<T>;
    enableAllValidationOnSave?: boolean;
    clearOnSuccess?: boolean;
    onSave?: (value: Partial<T>) => Promise<void>;
    onChange?: (value: Partial<T>) => void;
}

export interface UseFormResult<T> {
    data: T;
    errors: ValidationErrors<T>;
    isSaving: boolean;
    updateField: <TKey extends keyof T>(field: TKey, value: T[TKey]) => Promise<Partial<T>>;
    update: (value: Partial<T>) => Promise<Partial<T>>;
    submit: (lastSecondChangesHack?: Partial<T>) => Promise<ValidationErrors<T> | null>;
    clear: () => void;
    clearValidation: () => void;
    validate: (validateAll: boolean) => ValidationErrors<T> | null;
    getInputProps: (field: keyof T) => Partial<FormInputProps>;
    getSelectProps: (field: keyof T) => Partial<FormSelectProps>;
    getTextareaProps: (field: keyof T) => Partial<FormTextAreaProps>;
}

export function useForm<T>({
    initialValue,
    sourceData,
    onSave,
    autosaveInterval,
    validateAllFields = false,
    enableAllValidationOnSave = false,
    clearOnSuccess = false,
    fields = {},
}: UseFormConfig<T>): UseFormResult<T> {
    const [changes, setChanges] = useStateAsync<Partial<T>>({});
    const [inflightChanges, setInflightChanges] = useState<Partial<T>>({});
    const mergedChanges = useMemo(() => ({
        ...inflightChanges,
        ...changes,
    }), [inflightChanges, changes]);
    const mergedData = useMemo(() => ({
        ...initialValue,
        ...sourceData,
        ...mergedChanges,
    }), [initialValue, sourceData, mergedChanges]);

    const [isSaving, setIsSaving] = useState(false);
    const [clickedSave, setClickedSave] = useState(false);

    const validate = useCallback((data: Partial<T>): ValidationErrors<T> | null => {
        const errors: ValidationErrors<T> = {};
        Object.keys(data).forEach(k => {
            const field = fields && fields[k as keyof T];
            if (field) {
                const validator: FieldValidator<T, T[keyof T]> = typeof field === 'function' ? field : field.validator;
                const fieldData = data[k as keyof T];
                if (fieldData !== undefined) {
                    const error = validator(fieldData as T[keyof T], data);
                    if (error) {
                        errors[k as keyof T] = error;
                    }
                }
            }
        });
        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return null;
    }, [fields]);

    const validatedOnSave = useCallback(async (data: Partial<T>): Promise<ValidationErrors<T> | null> => {
        const errors = validate(data);
        if (errors !== null) {
            setClickedSave(true);
            return errors;
        }

        if (onSave) {
            setIsSaving(true);
            try {
                await onSave(data);
                setClickedSave(false); // reset after success
                if (clearOnSuccess) {
                    setChanges({});
                }
            }
            finally {
                setIsSaving(false);
            }
        }
        return null;
    }, [onSave, validate, clearOnSuccess, setChanges]);

    // TODO: usecallback?
    const onSaveDebounced = useDebouncedCallback(
        async (data: Partial<T>) => {
            if (onSave) {
                const originalChanges = changes;
                setInflightChanges(data);
                setChanges({});
                if (await validatedOnSave(data) !== null) {
                    setChanges({
                        ...originalChanges,
                        ...changes,
                    });
                }
                setInflightChanges({});
            }
        },
        autosaveInterval ?? 1,
    );

    const onSaveCallback = useCallback(async (lastSecondChangesHack?: Partial<T>): Promise<ValidationErrors<T> | null> => {
        let finalData = mergedData;
        let finalChanges = changes;
        if (lastSecondChangesHack) {
            finalData = { ...finalData, ...lastSecondChangesHack };
            finalChanges = { ...finalChanges, ...lastSecondChangesHack };
        }
        const errors = validate(finalData);
        if (errors !== null) {
            setClickedSave(true);
            return errors;
        }

        if (autosaveInterval) {
            await onSaveDebounced(finalChanges);
        }
        else if (onSave) {
            await validatedOnSave(finalChanges);
        }

        return null;
    }, [validate, mergedData, changes, autosaveInterval, onSave, onSaveDebounced, validatedOnSave]);

    useEffect(() => {
        if (autosaveInterval && Object.keys(changes).length > 0) {
            onSaveDebounced(changes);
        }
    }, [changes, onSaveDebounced, autosaveInterval]);

    const onChangeCallback = useCallback(async <TKey extends keyof T>(field: TKey, value: T[TKey]) => setChanges(existing => ({
        ...existing,
        [field]: value,
    })), [setChanges]);

    const onChangeAllCallback = useCallback(async (value: Partial<T>) => setChanges(existing => ({
        ...existing,
        ...value,
    })), [setChanges]);

    const onClearCallback = useCallback(() => {
        setChanges({});
    }, [setChanges]);

    const onClearValidationCallback = useCallback(() => {
        setClickedSave(false);
        const clears: Partial<T> = { ...changes };
        Object.keys(changes).forEach(key => {
            if (!changes[key as keyof T]) {
                delete clears[key as keyof T];
            }
        });
        setChanges(clears);
    }, [setChanges, changes]);

    const onValidateCallback = useCallback(
        (validateAll = false) => validate(validateAll ? mergedData : mergedChanges),
        [mergedData, mergedChanges, validate],
    );

    const validateAll = validateAllFields || (enableAllValidationOnSave && clickedSave);

    const errors = useMemo<ValidationErrors<T>>(
        () => validate(validateAll ? mergedData : mergedChanges) || {},
        [validate, validateAll, mergedData, mergedChanges],
    );

    const inputPropsCallback = useCallback((field: keyof T): Partial<FormInputProps> => ({
        name: field,
        value: mergedData[field],
        onChange: ev => onChangeCallback(field, ev.target.value as unknown as T[keyof T]),
        error: errors[field],
    }), [mergedData, errors, onChangeCallback]);

    const selectPropsCallback = useCallback((field: keyof T): Partial<FormSelectProps> => ({
        name: field,
        value: mergedData[field] as FormSelectProps['value'],
        onChange: (_, data) => onChangeCallback(field, data.value as unknown as T[keyof T]),
        error: errors[field],
    }), [mergedData, errors, onChangeCallback]);

    const textareaPropsCallback = useCallback((field: keyof T): Partial<FormTextAreaProps> => ({
        name: field,
        value: mergedData[field] as FormTextAreaProps['value'],
        onChange: ev => onChangeCallback(field, ev.target.value as unknown as T[keyof T]),
        error: errors[field],
    }), [mergedData, errors, onChangeCallback]);

    return {
        data: mergedData,
        errors,
        isSaving,
        updateField: onChangeCallback,
        update: onChangeAllCallback,
        submit: onSaveCallback,
        clear: onClearCallback,
        clearValidation: onClearValidationCallback,
        validate: onValidateCallback,
        getInputProps: inputPropsCallback,
        getSelectProps: selectPropsCallback,
        getTextareaProps: textareaPropsCallback,
    };
}
