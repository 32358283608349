import { FirebaseError } from 'firebase/app';
import {
    FacebookAuthProvider, fetchSignInMethodsForEmail, GoogleAuthProvider, linkWithCredential,
    OAuthCredential, signInWithPopup, TwitterAuthProvider,
} from 'firebase/auth';
import React, { useState } from 'react';
import { useAuth } from 'reactfire';
import { Button, Popup } from 'semantic-ui-react';
import styles from './ThirdPartySignIn.module.css';

export type ThirdPartySignInProvider = 'google' | 'facebook' | 'twitter' | 'apple' | 'microsoft';

export interface ThirdPartySignInProps {
    disabled?: boolean;
    onSignIn: (provider: ThirdPartySignInProvider) => void;
    onSignInFailed: (provider: ThirdPartySignInProvider, error: unknown) => void;
    onSignInStarted: (provider: ThirdPartySignInProvider) => void;
}

const supportedPopupSignInMethods: string[] = [
    GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
    TwitterAuthProvider.PROVIDER_ID,
];

const makeProvider = (providerId: string) => {
    switch (providerId) {
        case GoogleAuthProvider.PROVIDER_ID:
        case 'google':
            return new GoogleAuthProvider();
        case FacebookAuthProvider.PROVIDER_ID:
        case 'facebook':
            return new FacebookAuthProvider();
        case TwitterAuthProvider.PROVIDER_ID:
        case 'twitter':
            return new TwitterAuthProvider();
        default:
            throw new Error(`No provider implemented for ${providerId}`);
    }
};

const makeCredentialFromError = (providerId: string, error: FirebaseError): OAuthCredential | null => {
    switch (providerId) {
        case GoogleAuthProvider.PROVIDER_ID:
        case 'google':
            return GoogleAuthProvider.credentialFromError(error);
        case FacebookAuthProvider.PROVIDER_ID:
        case 'facebook':
            return FacebookAuthProvider.credentialFromError(error);
        case TwitterAuthProvider.PROVIDER_ID:
        case 'twitter':
            return TwitterAuthProvider.credentialFromError(error);
        default:
            throw new Error(`No provider implemented for ${providerId}`);
    }
};

export function ThirdPartySignIn({
    onSignIn, onSignInFailed, onSignInStarted, disabled = false,
}: ThirdPartySignInProps) {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);

    const onThirdPartySignIn = async (providerName: ThirdPartySignInProvider) => {
        if (isLoading) {
            return;
        }

        const provider = makeProvider(providerName);
        try {
            onSignInStarted(providerName);
            setIsLoading(true);
            provider.addScope('email');
            await signInWithPopup(auth, provider);
            onSignIn(providerName);
        }
        catch (error) {
            if (error instanceof FirebaseError
                && typeof error.customData?.email === 'string'
                && error.code === 'auth/account-exists-with-different-credential'
            ) {
                try {
                    const { email } = error.customData;

                    const providers = await fetchSignInMethodsForEmail(auth, email);
                    const firstPopupProviderMethod = providers.find(p => supportedPopupSignInMethods.includes(p));

                    // Test: Could this happen with email link then trying social provider?
                    if (!firstPopupProviderMethod) {
                        // throw new Error("Your account is linked to a provider that isn't supported.");
                        onSignInFailed(providerName, error);
                        return;
                    }

                    const linkedProvider = makeProvider(firstPopupProviderMethod);
                    linkedProvider.setCustomParameters({ login_hint: email });

                    const result = await signInWithPopup(auth, linkedProvider);
                    const credential = makeCredentialFromError(providerName, error);
                    if (!credential) {
                        onSignInFailed(providerName, error);
                        return;
                    }

                    await linkWithCredential(result.user, credential);
                    onSignIn(providerName);
                }
                catch (error2) {
                    onSignInFailed(providerName, error2);
                }
            }
            else {
                onSignInFailed(providerName, error);
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <Popup
                content="Sign in with Google"
                position="bottom center"
                trigger={(
                    <Button
                        circular
                        icon="google"
                        color="google plus"
                        size="huge"
                        disabled={disabled}
                        onClick={() => onThirdPartySignIn('google')}
                    />
                )}
            />
            <Popup
                content="Sign in with Facebook"
                position="bottom center"
                trigger={(
                    <Button
                        circular
                        icon="facebook"
                        color="facebook"
                        size="huge"
                        disabled={disabled}
                        onClick={() => onThirdPartySignIn('facebook')}
                    />
                )}
            />
            {/*
            <Popup
                content="Sign in with Twitter"
                position="bottom center"
                trigger={(
                    <Button
                        circular
                        icon="twitter"
                        color="twitter"
                        size="huge"
                        disabled={disabled}
                        onClick={() => onThirdPartySignIn('twitter')}
                    />
                )}
            />
            <Popup
                content="Sign in with Apple"
                position="bottom center"
                trigger={(
                    <Button
                        circular
                        icon="apple"
                        // color="apple"
                        size="huge"
                        disabled={disabled}
                        onClick={() => onThirdPartySignIn('apple')}
                    />
                )}
            />
            <Popup
                content="Sign in with Microsoft"
                position="bottom center"
                trigger={(
                    <Button
                        circular
                        icon="microsoft"
                        color="blue"
                        size="huge"
                        disabled={disabled}
                        onClick={() => onThirdPartySignIn('microsoft')}
                    />
                )}
            /> */}
        </div>
    );
}
