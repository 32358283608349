import React from 'react';
import {
    Grid, Container, Message, Button,
} from 'semantic-ui-react';

export function ErrorScreen({ error, resetErrorBoundary }: { error: Error, resetErrorBoundary: () => void }) {
    const reloadPage = () => {
        window.location.reload();
    };

    return (
        <Grid>
            <Grid.Column>
                <Container>
                    <Message negative>
                        <Message.Header>An unexpected error occurred</Message.Header>
                        <p>
                            Unknown error:
                            {' '}
                            {error.message}
                        </p>
                        <Button onClick={resetErrorBoundary} primary>Try again</Button>
                        <Button onClick={reloadPage} secondary>Reload page</Button>
                    </Message>
                </Container>
            </Grid.Column>
        </Grid>
    );
}
