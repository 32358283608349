import { User } from 'firebase/auth';
import { ref } from 'firebase/database';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    useAuth, useDatabase, useDatabaseObjectData, useUser,
} from 'reactfire';
import {
    Menu, Container, Icon, Dropdown, SemanticShorthandItem, IconProps, SemanticICONS,
} from 'semantic-ui-react';

import logo from '../logo.svg';
import { UserDataModel } from '../models/UserDataModel';
import { FeedbackButton } from './Feedback/FeedbackButton';
import styles from './NavBar.module.css';

type DropdownLinkProps = {
    icon?: SemanticShorthandItem<IconProps>;
    href: string;
    children?: React.ReactNode;
};
function DropdownLink({
    children, icon, href,
}: DropdownLinkProps) {
    const props = href.startsWith('/') ? {
        as: Link,
        to: href,
    } : {
        as: 'a',
        target: '_blank',
        href,
    };
    return (
        <Dropdown.Item
            text={children}
            icon={icon}
            {...props}
        />
    );
}

type MenuLinkProps = {
    icon?: SemanticICONS;
    href: string;
    children?: React.ReactNode;
    className?: string;
};
function MenuLink({
    children, icon, href, className = '',
}: MenuLinkProps) {
    const props = href.startsWith('/') ? {
        as: Link,
        to: href,
        className,
    } : {
        as: 'a',
        target: '_blank',
        href,
        className,
    };
    return (
        <Menu.Item item {...props}>
            {icon && <Icon name={icon} />}
            {children}
        </Menu.Item>
    );
}

interface AdminButtonProps {
    user: User;
    className?: string;
    flat?: boolean;
}
function AdminButton({ user, className = '', flat = false }: AdminButtonProps) {
    const userDataRef = ref(useDatabase(), `users/${user.uid}`);
    const { data: userData, status } = useDatabaseObjectData<UserDataModel>(userDataRef, { idField: 'id' });

    if (status !== 'success' || userData?.role !== 'admin') {
        return null;
    }

    if (flat) {
        return (
            <>
                <DropdownLink icon="user" href="/admin/users">Manage Users</DropdownLink>
                <DropdownLink icon="bullhorn" href="/admin/feedback">Manage Feedback</DropdownLink>
            </>
        );
    }

    return (
        <Dropdown item text="Admin" icon="fire extinguisher" className={`${styles.dropdownGroup} ${className}`}>
            <Dropdown.Menu>
                <DropdownLink icon="user" href="/admin/users">Manage Users</DropdownLink>
                <DropdownLink icon="bullhorn" href="/admin/feedback">Manage Feedback</DropdownLink>
            </Dropdown.Menu>
        </Dropdown>
    );
}

function MenuFeedbackButton() {
    return (
        <FeedbackButton>
            {({ show }) => (
                <Dropdown.Item text="Feedback" icon="bullhorn" onClick={show} />
            )}
        </FeedbackButton>
    );
}

export function NavBar() {
    const { data: user } = useUser();
    const auth = useAuth();

    const logout = () => {
        auth.signOut();
    };

    return (
        <>
            <Menu inverted size="massive" borderless fixed="top" className={styles.navbar}>
                <Container>
                    <Dropdown item icon="bars" className={styles.hamburger}>
                        <Dropdown.Menu>
                            {user && (
                                <Dropdown.Item>
                                    Welcome back,
                                    {' '}
                                    {user.displayName}
                                    !
                                </Dropdown.Item>
                            )}
                            <MenuLink icon="home" href="/">Dashboard</MenuLink>
                            <DropdownLink icon="shop" href="https://stockrocket.us/collections/all">Store</DropdownLink>
                            <DropdownLink icon="wrench" href="https://stockrocket.us/pages/troubleshooting">Troubleshooting</DropdownLink>
                            {/* <DropdownLink icon="question" href="https://stockrocket.us/pages/faq">FAQ</DropdownLink> */}
                            <MenuFeedbackButton />
                            {user && (<DropdownLink icon="chain" href="/welcome">Link New Device</DropdownLink>)}
                            {user && (<AdminButton user={user} flat />)}
                            {user && (
                                <Dropdown.Item icon="log out" onClick={logout} text="Log out" />
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <MenuLink href="/">
                        <img src={logo} alt="Stock Rocket Logo" className={styles.logo} />
                        {/* Stock Rocket 🚀 */}
                    </MenuLink>
                    <MenuLink icon="home" href="/" className={styles.menuItem}>Dashboard</MenuLink>
                    <MenuLink icon="shop" href="https://stockrocket.us/collections/all" className={styles.menuItem}>Store</MenuLink>
                    <Dropdown item text="Support" icon="question" className={`${styles.dropdownGroup} ${styles.menuItem}`}>
                        <Dropdown.Menu>
                            <DropdownLink icon="wrench" href="https://stockrocket.us/pages/troubleshooting">Troubleshooting</DropdownLink>
                            {/* <DropdownLink icon="question" href="https://stockrocket.us/pages/faq">FAQ</DropdownLink> */}
                            <MenuFeedbackButton />
                        </Dropdown.Menu>
                    </Dropdown>
                    {user && (<MenuLink icon="chain" href="/welcome" className={styles.menuItem}>Link New Device</MenuLink>)}
                    {user && (<AdminButton user={user} className={styles.menuItem} />)}
                    {user && (
                        <Menu.Menu position="right" className={styles.menuItem}>
                            <Menu.Item>
                                Welcome back,
                                {' '}
                                {user.displayName}
                                !
                            </Menu.Item>
                            <Menu.Item onClick={logout}>
                                Log out
                            </Menu.Item>
                        </Menu.Menu>
                    )}
                </Container>
            </Menu>
            <div className={styles.menuPadding} />
        </>
    );
}
