import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import styles from './LoginContainer.module.css';

export interface LoginContainerProps {
    children: React.ReactNode;
    loading?: boolean;
    textAlign?: 'left' | 'center' | 'right';
}

export function LoginContainer({ children, loading = false, textAlign = 'center' }: LoginContainerProps) {
    return (
        <>
            <div className={styles.backgroundColorOverride} />
            <Grid container className={styles.container} textAlign="center">
                <Grid.Column width={8} mobile={16}>
                    <Segment raised loading={loading} textAlign={textAlign}>
                        {children}
                    </Segment>
                </Grid.Column>
            </Grid>
        </>
    );
}
