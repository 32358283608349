import { FirebaseError } from 'firebase/app';
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'reactfire';
import { Form, Header, Message } from 'semantic-ui-react';
import { LoginContainer } from '../components/LoginForm/LoginContainer';
import { defaultFormValues, forgotPasswordFormFields, UserAccountModel } from '../models/UserAccountModel';
import { getFirebaseErrorMessage } from '../utils/firebaseErrorMappings';
import { useDocumentTitle } from '../utils/useDocumentTitle';
import { useForm } from '../utils/useForm';
import { useTimedValue } from '../utils/useTimedValue';

import styles from './LoginForm.module.css';

export function ForgotPassword() {
    const auth = useAuth();
    useDocumentTitle('Forgot Password');

    const {
        value: submitButtonValue,
        isRunning: submitButtonValueAlt,
        setValue: setSubmitButtonValue,
    } = useTimedValue('Reset password', 5000);
    const [sendError, setSendError] = useState<string | null>(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirectUrl = searchParams.get('redirect');

    const onSave = async (data: Partial<UserAccountModel>) => {
        if (!data.email) {
            return;
        }

        try {
            const options = redirectUrl ? { url: redirectUrl } : undefined;
            await sendPasswordResetEmail(auth, data.email, options);
            setSubmitButtonValue('Sent email');
        }
        catch (e) {
            console.error(e);
            if (e instanceof FirebaseError) {
                setSubmitButtonValue('Error sending password reset');
                setSendError(getFirebaseErrorMessage(e.code));
            }
        }
    };

    const {
        isSaving,
        getInputProps,
        submit,
    } = useForm({
        fields: forgotPasswordFormFields,
        initialValue: defaultFormValues,
        enableAllValidationOnSave: true,
        onSave,
    });

    const signUpUrl = `/sign-up${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;
    const signInUrl = `/sign-in${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`;

    return (
        <LoginContainer textAlign="left">
            <Header as="h1" textAlign="center">
                Forgot password?
            </Header>
            <p>Enter your email address and we&apos;ll send you instructions on how to reset your password.</p>
            <Form error={!!sendError}>
                <Message
                    error
                    content={sendError}
                />
                <Form.Input
                    type="text"
                    placeholder="Email address"
                    icon="mail"
                    iconPosition="left"
                    className={styles.formField}
                    {...getInputProps('email')}
                />
                <p>
                    <Link to={signInUrl}>Back to sign in</Link>
                </p>
                <p>
                    <Link to={signUpUrl}>Don&apos;t have an account?</Link>
                </p>
                <Form.Button
                    primary
                    fluid
                    loading={isSaving}
                    content={submitButtonValue}
                    onClick={() => submit()}
                    disabled={submitButtonValueAlt || isSaving}
                />
            </Form>
        </LoginContainer>
    );
}
