import React from 'react';
import {
    Grid, Container, Segment, Placeholder,
} from 'semantic-ui-react';

export function DeviceListPlaceholder() {
    return (
        <Grid>
            <Grid.Column>
                <Container>
                    {[...Array(3)].map((_, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Segment key={i}>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Segment>
                    ))}
                </Container>
            </Grid.Column>
        </Grid>
    );
}
