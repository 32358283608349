import { useStorage } from 'reactfire';
import { getDownloadURL, ref as refStorage } from 'firebase/storage';
import React, {
    createContext, ReactNode, useContext, useEffect, useMemo,
} from 'react';
import axios from 'axios';

import { SymbolInfo } from '../utils/types';

export interface StockData {
    symbols: { [symbol: string]: SymbolInfo };
    isLoading: boolean;
}
export const StockDataContext = createContext<StockData>({ symbols: {}, isLoading: true });

export interface StockDataProviderProps {
    children: ReactNode,
}
export function StockDataProvider({ children }: StockDataProviderProps) {
    const storage = useStorage();
    const [symbols, setSymbols] = React.useState<{ [symbol: string]: SymbolInfo }>({});
    useEffect(() => {
        // TODO: download directly? https://firebase.google.com/docs/storage/web/download-files
        getDownloadURL(refStorage(storage, 'stock-data/symbols.json')).then(url => {
            axios.get(url).then(res => {
                const data: { [symbol: string]: SymbolInfo } = {};
                Object.keys(res.data as { [symbol: string]: SymbolInfo }).forEach(id => {
                    data[id] = {
                        ...res.data[id],
                        uniqId: id,
                    };
                });
                setSymbols(data);
            });
        });
    }, [storage]);

    const stockData: StockData = useMemo(() => ({ symbols, isLoading: Object.keys(symbols).length === 0 }), [symbols]);

    return (
        <StockDataContext.Provider value={stockData}>
            {children}
        </StockDataContext.Provider>
    );
}

export const useStockData = () => useContext(StockDataContext);
