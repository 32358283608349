import React from 'react';
import { Icon, Popup, SemanticCOLORS } from 'semantic-ui-react';

export interface InfoIconProps {
    tooltip: string;
    color?: SemanticCOLORS;
}

export function InfoIcon({ tooltip, color = 'grey' }: InfoIconProps) {
    return (
        <Popup
            content={tooltip}
            trigger={(
                <Icon
                    name="info circle"
                    color={color}
                    link
                />
            )}
        />
    );
}
