import {
    endAt, endBefore, limitToFirst, limitToLast, onValue, Query, QueryConstraint, startAfter, startAt,
} from 'firebase/database';
import {
    useCallback, useEffect, useMemo, useReducer,
} from 'react';
import { ObservableStatus } from 'reactfire';

interface ModelWithId {
    id: string;
}

export interface UsePaginatedResult<T extends ModelWithId> {
    prev: () => void;
    next: () => void;
    setPage: (page: number) => void;
    currentPage: number;
    knownTotalPages: number;
    data: ObservableStatus<T[] | null>['data'];
    status: ObservableStatus<T[] | null>['status'];
}

export interface UsePaginatedOptions<T extends ModelWithId> {
    pageSize: number;
    reverse?: boolean;
    queryBuilder: (constraints: QueryConstraint[]) => Query;
    sortKey: keyof T;
}

type FbDataType = string | number | boolean | null;

// const queryOptions = {
//     idField: 'id',
// };

// https://firebase.google.com/docs/database/web/lists-of-data#orderbychild
// -1: a < b
// 0 : a == b
// 1 : a > b
function firebaseValueCompare(a: FbDataType, b: FbDataType): number {
    if (a === b) {
        return 0;
    }

    if (a === null) {
        return -1;
    }
    if (b === null) {
        return 1;
    }

    if (a === false) {
        return -1;
    }
    if (b === false) {
        return 1;
    }

    if (a === true) {
        return -1;
    }
    if (b === true) {
        return 1;
    }

    if (typeof a === 'number' && typeof b !== 'number') {
        return -1;
    }
    if (typeof a !== 'number' && typeof b === 'number') {
        return 1;
    }
    if (typeof a === 'number' && typeof b === 'number') {
        return Math.sign(a - b);
    }

    if (typeof a === 'string' && typeof b !== 'string') {
        return -1;
    }
    if (typeof a !== 'string' && typeof b === 'string') {
        return 1;
    }
    if (typeof a === 'string' && typeof b === 'string') {
        return a < b ? -1 : 1;
    }

    return 0;
}

// https://firebase.google.com/docs/database/web/lists-of-data#orderbychild
// -1: a < b
// 0 : a == b
// 1 : a > b
function firebaseKeyCompare(a: string, b: string): number {
    if (a === b) {
        return 0;
    }

    const aIsNumber = /^\d+$/.test(a);
    const bIsNumber = /^\d+$/.test(b);

    if (aIsNumber && !bIsNumber) {
        return -1;
    }
    if (!aIsNumber && bIsNumber) {
        return 1;
    }
    if (aIsNumber && bIsNumber) {
        return Math.sign(parseInt(a, 10) - parseInt(b, 10));
    }

    return a < b ? -1 : 1;
}

interface UsePaginatedState<T extends ModelWithId> {
    cursors: [FbDataType, string][];
    page: number;
    data: T[] | null;
    loading: boolean;
    requestId: number;
}

type StateReducerAction<T> = {
    type: 'setPage';
    payload: number;
} | {
    type: 'setData';
    payload: T[] | null;
    requestId: number;
    page: number;
    sortKey: keyof T;
    reverse: boolean;
    pageSize: number;
} | {
    type: 'addCursor';
    payload: [FbDataType, string];
} | {
    type: 'clear';
};

type StateReducer<T extends ModelWithId> = (state: UsePaginatedState<T>, action: StateReducerAction<T>) => UsePaginatedState<T>;
const stateReducer = <T extends ModelWithId>(
    state: UsePaginatedState<T>,
    action : StateReducerAction<T>,
): UsePaginatedState<T> => {
    if (action.type === 'setPage') {
        return {
            ...state,
            page: action.payload,
        };
    }

    if (action.type === 'setData') {
        const { sortKey, reverse, pageSize } = action;

        if (action.requestId !== state.requestId || action.page !== state.page) {
            return state;
        }

        const lastResult = action.payload ? action.payload[action.payload.length - 1] : null;
        let { cursors } = state;

        if (state.page - 1 > cursors.length || action.payload?.length !== pageSize) {
            // noop
        }
        else if (lastResult && cursors.length === 0) {
            // TODO: type this better?
            const newKey = lastResult[sortKey] as unknown as FbDataType ?? null;
            const { id: newId } = lastResult;
            if (!newId) {
                throw new Error('Expected id');
            }
            cursors = [...cursors, [newKey, newId]];
        }
        else if (lastResult) {
            const newKey = lastResult[sortKey] as unknown as FbDataType ?? null;
            const { id: newId } = lastResult;
            if (!newId) {
                throw new Error('Expected id');
            }
            const [lastKey, lastId] = cursors[cursors.length - 1];

            const expectedCompare = reverse ? -1 : 1;
            const keyCompare = firebaseValueCompare(newKey, lastKey);
            const idCompare = firebaseKeyCompare(newId, lastId);

            // console.log(lastKey, newKey, keyCompare, lastId, newId, idCompare, expectedCompare);
            if (keyCompare === expectedCompare || (keyCompare === 0 && idCompare === expectedCompare)) {
                cursors = [...cursors, [newKey, newId]];
            }
        }

        return {
            ...state,
            data: action.payload,
            cursors,
            loading: false,
        };
    }

    if (action.type === 'clear') {
        return {
            ...state,
            cursors: [],
            page: 1,
            loading: true,
            requestId: state.requestId + 1,
        };
    }

    return state;
};

export function usePaginated<T extends ModelWithId>({
    pageSize, queryBuilder, sortKey, reverse = false,
}: UsePaginatedOptions<T>): UsePaginatedResult<T> {
    const [state, dispatch] = useReducer<StateReducer<T>>(
        stateReducer,
        {
            cursors: [],
            page: 1,
            data: null,
            loading: true,
            requestId: 0,
        },
    );
    const {
        cursors, page, data, requestId,
    } = state;

    const limitFn = reverse ? limitToLast : limitToFirst;
    const startAfterFn = useCallback((value: FbDataType, key: string) => {
        const fn = reverse ? endBefore : startAfter;
        if (sortKey === 'id') {
            return fn(key);
        }
        return fn(value, key);
    }, [reverse, sortKey]);
    const endAtFn = useCallback((value: FbDataType, key: string) => {
        const fn = reverse ? startAt : endAt;
        if (sortKey === 'id') {
            return fn(key);
        }
        return fn(value, key);
    }, [reverse, sortKey]);

    const queryConstraints: QueryConstraint[] = useMemo(() => {
        if (page === 1 && cursors.length >= 1) {
            return [
                endAtFn(...cursors[page - 1]),
            ];
        }
        if (page <= cursors.length) {
            return [
                endAtFn(...cursors[page - 1]),
                startAfterFn(...cursors[page - 2]),
            ];
        }
        if (cursors.length > 0) {
            return [
                startAfterFn(...cursors[cursors.length - 1]),
                limitFn(pageSize),
            ];
        }

        return [
            limitFn(pageSize),
        ];
    }, [cursors, page, pageSize, limitFn, startAfterFn, endAtFn]);

    const query = useMemo(() => queryBuilder(queryConstraints), [queryConstraints, queryBuilder]);

    useEffect(() => {
        dispatch({
            type: 'clear',
        });
    }, [queryBuilder, reverse, pageSize, sortKey]);

    useEffect(() => {
        onValue(query, d => {
            const results: T[] = [];
            d.forEach(item => {
                const tojson = item.toJSON() as T | null;
                if (tojson && item.key) {
                    tojson.id = item.key;
                }
                if (tojson) {
                    results.push(tojson);
                }
            });
            if (reverse) {
                results.reverse();
            }
            dispatch({
                type: 'setData',
                payload: results,
                requestId,
                page,
                reverse,
                pageSize,
                sortKey,
            });
        }, { onlyOnce: true });
    }, [query, reverse, pageSize, sortKey, page, requestId]);

    return {
        prev: () => {
            if (page > 1) {
                dispatch({
                    type: 'setPage',
                    payload: page - 1,
                });
            }
        },
        next: () => {
            dispatch({
                type: 'setPage',
                payload: page + 1,
            });
        },
        setPage: (p: number) => {
            dispatch({
                type: 'setPage',
                payload: p,
            });
        },
        currentPage: page,
        data,
        status: 'success',
        knownTotalPages: Math.max(1, cursors.length),
    };
}
